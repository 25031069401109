import React, { useState, useEffect } from "react";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import Organicsearchtraffic from "./Organicsearchtraffic";
import Trafficacquisition from "./Trafficacquisition";
import Demographicdetails from "./Demographicdetails";
import TechDetails from "./TechDetails";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Loader from "../CommonLoader";
import { Commonfun } from './../Commonfun';
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

export default function OrganicsearchClicks() {
  const [cardData, setCardData] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [dimendataval, setDimendataval] = useState("");
  const [selmetricval, setSelmetricval] = useState("");
  const [loading, setLoading] = useState(false);



  const [selectval, setSelectval] = useState("");
  const esodata = useSelector((state) => state);
  const ApiToken=esodata.apiauthkey.value

  const [CurrentMenu,SetCurrentMenu]=useState('organic_search_traffic')
  const [CurrentMenuName,SetCurrentMenuName]=useState('Google Organic Search Traffic')
  const [faqData,setfaqData]=useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval,SetInitialfaqDateval] = useState([]);
  const navigate = useNavigate();
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const SwitchMenu=(e)=>{

    const Id=e.target.getAttribute('data-id')
    const Name=e.target.getAttribute('data-name')
    SetCurrentMenu(Id)
    SetCurrentMenuName(Name)
    faqdatafun(Name)
  }
  const handleClose = () => {
    setOpen(false);
  };


  const [FilterButtonTriggered,SetFilterButtonTriggered]=useState(false)

  

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const FilterFunction=()=>{
    SetFilterButtonTriggered(true)
  }
  const pdfdown = async () =>{ 
    setLoading(true);
   if(CurrentMenu==='organic_search_traffic')
   var urldata="google_organic_search_traffic";
  else if(CurrentMenu==='traffic_acquisition')
  var urldata="google_traffic_acquisition";
  else if(CurrentMenu==='demographic_details')
  var urldata="demographic_details";
  else if(CurrentMenu==='tech_details')
  var urldata="tech_details";
    try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}googleanalytics/${urldata}/?user_domain_id=${esodata.domaindata.value.header_selected}&dimension=${dimendataval}&selected_metrics=${selmetricval}&formats=pdf`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();
  
                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                if(CurrentMenu==='organic_search_traffic')
                  var googledata="Google Organic Search Traffic";
                 else if(CurrentMenu==='traffic_acquisition')
                 var googledata="Traffic Acquisition";
                 else if(CurrentMenu==='demographic_details')
                 var googledata="Demographic Details";
                 else if(CurrentMenu==='tech_details')
                 var googledata="Tech Details";
                var filedata=await Commonfun(googledata,"pdf", ApiToken,esodata.domaindata.value.header_selected);
                // return false;
                link.download = filedata;
                link.click();  
  
                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              if(response?.status==599 || response?.status==500 || response?.status==400  || response?.status==404 ){
                response.json().then(data => {
                  setapiOpen(true);
                  setapierrorMsg(data?.error)
                  console.log('Resolved object:', data.error);
                })
              }
              else if(response?.code =="ERR_NETWORK"){
                navigate('/errorpages')    
              }
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);
  
  }
  const csvdown = async () =>{ 
    setLoading(true);
    if(CurrentMenu==='organic_search_traffic')
    var urldata="google_organic_search_traffic";
   else if(CurrentMenu==='traffic_acquisition')
   var urldata="google_traffic_acquisition";
   else if(CurrentMenu==='demographic_details')
   var urldata="demographic_details";
   else if(CurrentMenu==='tech_details')
   var urldata="tech_details";
    try{
      const response = await fetch(`${process.env.REACT_APP_API_URL}googleanalytics/${urldata}/?user_domain_id=${esodata.domaindata.value.header_selected}&dimension=${dimendataval}&selected_metrics=${selmetricval}&formats=csv`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();
  
                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                if(CurrentMenu==='organic_search_traffic')
                  var googledata="Google Organic Search Traffic";
                 else if(CurrentMenu==='traffic_acquisition')
                 var googledata="Traffic Acquisition";
                 else if(CurrentMenu==='demographic_details')
                 var googledata="Demographic Details";
                 else if(CurrentMenu==='tech_details')
                 var googledata="Tech Details";
                var filedata=await Commonfun(googledata,"csv", ApiToken,esodata.domaindata.value.header_selected);
                // return false;
                link.download = filedata;
                link.click(); 
  
                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              if(response?.status==599 || response?.status==500 || response?.status==400  || response?.status==404 ){
                response.json().then(data => {
                  setapiOpen(true);
                  setapierrorMsg(data?.error)
                  console.log('Resolved object:', data.error);
                })
              }
              else if(response?.code =="ERR_NETWORK"){
                navigate('/errorpages')    
              }
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);
  }
  useEffect(()=>{   
  // alert("dimen"+dimendataval)
  // alert("metric"+selmetricval)
 var currentMenus=CurrentMenu;
  faqdatafun(currentMenus)
},[dimendataval,selmetricval])
const e = document.getElementById('root');
e.addEventListener('click', function handleClick(event) {
  console.log(event.target.classList[0])
  if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') || 
  (event.target.classList[0] === 'nav_key_txt') || 
  (event.target.classList[0] === 'cnt_hdr_top_wrap') ||(event.target.classList[0] === 'react-dropdown-select') 
  ||(event.target.classList[0] === 'react-dropdown-select-content') 
  ||(event.target.classList[0] === 'react-dropdown-select-item') 
  || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap')   || (event.target.classList[0] === 'App')  
  || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
   || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row') 
   || (event.target.classList[0] === 'MuiDataGrid-cell')  || (event.target.classList[0] === 'MuiDataGrid-cellContent')
   || (event.target.classList[0] === 'header_inner_btm_wrap')||(event.target.classList[0] === 'over_all_container') 
   ||(event.target.classList[0] === 'input_domain_field') ||(event.target.classList[0] === 'react-dropdown-select-input')
   || (event.target.classList[0] === 'run_rpt_btn')  || (event.target.classList[0] === 'input_col') || (event.target.classList[0] === 'plagarism_analyzer_row')
   || (event.target.classList[0] === 'MuiDataGrid-cell--withRenderer') ||  (event.target.classList[0] === 'link_rn_rpt_btn')
   || (event.target.classList[0] === 'drop-organicsearch') ||  (event.target.classList[0] === 'link_rn_rpt_btn')
  
  ) {
   
    setfaqData(2)
    setToggle(false)   
  }
});
const faqdatafun = async(menubutval="") => {
  if(menubutval==='organic_search_traffic')
  var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=33`
  else if(menubutval==='traffic_acquisition')
  var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=34`
  else if(menubutval==='demographic_details')
  var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=35`
  else if(menubutval==='tech_details')
  var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=36`


  try {
    const doaminsectiondatavalue = await axios.get(
      datavar,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    );
    if (doaminsectiondatavalue.data) {
     console.log("domain data",doaminsectiondatavalue?.data?.faqs)
      SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
    }
  } catch (err) {}
}
const handleToggle = () => {
  if(faqData!=1){
    setfaqData(1)
  }
  else{
    setfaqData(2)
  }
      setToggle((pre) => !pre);
};
  return (
    <div className="over_all_container">
         <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Google Organic Search Traffic </title>
            </Helmet>
    <div className="cnt_audit_outer_wrap">
      <div className="cnt_hdr_top_wrap">
        <div className="cnt_hdr_top_lft_wrap">
          <h2>{CurrentMenuName}</h2>
          {/* <p>as of {jobDate}</p> */}
        </div>
        <div className="cnt_hdr_top_rgt_wrap">
        <div className={`App ${faqData=="1"?'faq_move_class':""}`} >
            <button onClick={handleToggle} className="faq_class"  title="Frequently Asked Question">
            <QuestionMarkIcon />
              
            </button>
           {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />} 
          </div>
        <img src={pdficon} alt="pdf icon" onClick={pdfdown}/>
        <img src={csvicon} alt="csv icon" onClick={csvdown} />
        </div>
      </div>
      
      <div className="link_cnt_hdr_blw_wrap">

     <button data-id="organic_search_traffic" className={CurrentMenu==='organic_search_traffic' ? 'link_rn_rpt_btn active' : 'link_rn_rpt_btn'} onClick={SwitchMenu} data-name='Organic Search Traffic'>
     Organic Search Traffic
        </button>
 
      <button data-id="traffic_acquisition" className={CurrentMenu==='traffic_acquisition' ? 'link_rn_rpt_btn active' : 'link_rn_rpt_btn'} onClick={SwitchMenu} data-name='Traffic Acquisition'>
      Traffic Acquisition
        </button>
       <button  data-id="demographic_details" className={CurrentMenu==='demographic_details' ? 'link_rn_rpt_btn active' : 'link_rn_rpt_btn'} onClick={SwitchMenu} data-name='Demographic Details'>
       Demographic Details
        </button>
       <button data-id="tech_details" className={CurrentMenu==='tech_details' ? 'link_rn_rpt_btn active' : 'link_rn_rpt_btn'} onClick={SwitchMenu} data-name='Tech Details'>
          Tech Details 
        </button>

      
      </div>

 {

  CurrentMenu==='organic_search_traffic' ?   <Organicsearchtraffic RankJob={selectval} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} jobDate={jobDate} setjobDate={setjobDate} closeModal={closeModal} dimendataval={dimendataval} setDimendataval={setDimendataval} selmetricval={selmetricval} setSelmetricval={setSelmetricval} /> : null
 }

 {

CurrentMenu==='traffic_acquisition' ?   <Trafficacquisition RankJob={selectval} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} jobDate={jobDate} setjobDate={setjobDate} closeModal={closeModal}  dimendataval={dimendataval} setDimendataval={setDimendataval} selmetricval={selmetricval} setSelmetricval={setSelmetricval}/> : null

 }

{

CurrentMenu==='demographic_details' ?   <Demographicdetails RankJob={selectval}     setSelectval={setSelectval} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} jobDate={jobDate} setjobDate={setjobDate} closeModal={closeModal} dimendataval={dimendataval} setDimendataval={setDimendataval} selmetricval={selmetricval} setSelmetricval={setSelmetricval}/> : null

 }

{

CurrentMenu==='tech_details' ?   <TechDetails RankJob={selectval} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} jobDate={jobDate} setjobDate={setjobDate} closeModal={closeModal} dimendataval={dimendataval} setDimendataval={setDimendataval} selmetricval={selmetricval} setSelmetricval={setSelmetricval}/> : null

 }
    </div>
    <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}
