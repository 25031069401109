import logo from "./logo.svg";
import React, { useState, useEffect ,useRef} from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
} from "react-router-dom";
import Home from "./pages/NavBar/header";
import Sidebar from "./pages/NavBar/NavBar";
import Auditsummary from "./pages/NavBar/Auditsummary";
import Schemaanalysis from "./pages/NavBar/Schemaanalysis";
import Schemadetail from "./pages/NavBar/schema/Schemadetail";
import ContentauditDetails from "./pages/NavBar/Contentaudit/ContentauditDetail";
import Codeauditlist from "./pages/NavBar/codeaudit/Codeauditlist";
import CodeauditDetail from "./pages/NavBar/codeaudit/CodeauditDetail";
import Plagiarisamlist from "./pages/NavBar/plagiarisam/Plagiarisamlist";
import Plagiarisamdetail from "./pages/NavBar/plagiarisam/Plagiarisamdetail";
import Plagiarisamscandetail from "./pages/NavBar/plagiarisam/Plagiarisamscandetail";
import Linkchecklists from "./pages/NavBar/linkchecker/Linkchecklists";
import Siteanalysis from "./pages/NavBar/siteaudit/Siteanalysis";
import Sitepageanalysis from "./pages/NavBar/siteaudit/Sitepageanalysis";
import Sitecompetoranalysis from "./pages/NavBar/siteaudit/Sitecompetoranalysis";
import Siteonanalysis from "./pages/NavBar/siteaudit/Siteonanalysis";
import Pagehealthlists from "./pages/NavBar/siteaudit/Pagehealthlists";
import Pagehealthdetail from "./pages/NavBar/siteaudit/Pagehealthdetail";
import Imagedetails from "./pages/NavBar/siteaudit/ImageDetails";
import Missingimagedetails from "./pages/NavBar/siteaudit/Missingimagedetails";
// import Tarketkeywordslist from "./pages/NavBar/targetkeywords/Tarketkeywordslist";
import Performancelist from "./pages/NavBar/Performance/Performancelist";
import Performancedetail from "./pages/NavBar/Performance/Detail";
import Accessbilitydetail from "./pages/NavBar/AccessbilityReport/Detail";
import Accessbilityfile from "./pages/NavBar/Accessibilityfile/Generate";
import Accessbilityfiledetail from "./pages/NavBar/Accessibilityfile/Detail";
import Regressiontest from "./pages/NavBar/regressiontest/Regressionlist";
import Spellcheckerlist from "./pages/NavBar/spellchecker/Spellcheckerlist";
import SpellcheckerDetail from "./pages/NavBar/spellchecker/SpellcheckerDetail";
import AccessibilityList from "./pages/NavBar/AccessbilityScore/List";
import AccessbilityScoreDetails from "./pages/NavBar/AccessbilityScore/Detail";
import InternallinkSource from "./pages/NavBar/linkchecker/InternallinkSource";
// import BackLinks from "./pages/NavBar/backLinks/BackLinks";
// import NewLinks from "./pages/NavBar/backLinks/NewLinks";
import InternallinkOutbound from "./pages/NavBar/linkchecker/InternalLinkOutbound";
import ExternalLinkLists from "./pages/NavBar/linkchecker/ExternalLink";
import PerformnceLevarageList from "./pages/NavBar/Performance/PerformanceLevarage";
import PerformnceRenderBlockingList from "./pages/NavBar/Performance/PerformanceRenderBlocking";
import GoogleLoginCom from "./pages/NavBar/Login";
import AppleLoginCom from "./pages/NavBar/AppleLogin";
import KeywordsList from "./pages/NavBar/KeyWords/KeyWordsList";
import AddcampaignList from "./pages/NavBar/KeyWords/Addcampaigndata";
import Addbrands from "./pages/NavBar/KeyWords/Addbrands";
import Searchkeyvolumelist from "./pages/NavBar/searchkeyvolume/Searchkeyvolumelist";
import Standardreport from "./pages/NavBar/standardreport/Standardreport";
import Competitorlist from "./pages/NavBar/Competitor_ranking/Competitorlist";
import MissedOpportunity from "./pages/NavBar/MissedOpportunity/Missedopportunity";
import Rankingsummary from "./pages/NavBar/Rankingsummary/Rankingsummarylists";
import LoginPage from "./pages/login/loginSection";
import ResetPassword from "./pages/login/passwordreset";
import ResetSuccess from "./pages/login/ResetSuccess";
import ResetPasswordConformation from "./pages/login/conformSection";
import ApprovePassword from "./pages/login/ApprovePassword";
import VisibilityScoreDetail from "./pages/NavBar/Rankingsummary/VisibilityScoreDetails";
import LinkedDomainList from "./pages/NavBar/Backllinks/LinkedDomainList";
import RankingListDetail from "./pages/NavBar/Rankingsummary/RankingListDetail";
import BackLinksIndex from "./pages/NavBar/Backllinks/BacklinkIndex";
import Linklist from "./pages/NavBar/Backllinks/New_link/Linklists";
import MainDashboard from "./pages/NavBar/Dashboard/Dashboard";
import LoginMiddleWare from "./pages/login/LoginMiddleware";
import ChangePassword from "./pages/login/ChangePassword";
import Myaccount from "./pages/login/Myaccount";
import ScrollToTop from "./ScrolltoTop";
import CheckMail from "./pages/Registration/CheckMail";
import Userdata from "./pages/NavBar/LoginDetail/Userdata";
import Domaindata from "./pages/NavBar/LoginDetail/Domaindata";
import Domainownership from "./pages/NavBar/LoginDetail/Domainownership";
import Googleanalytics from "./pages/NavBar/LoginDetail/Googleanalytics";
import DomainList from "./pages/NavBar/UserDomainList/UserDomainList";
import UserRole from "./pages/NavBar/UserRole/UserRole";
import AddForm from "./pages/NavBar/UserRole/AddForm"
import EditForm from "./pages/NavBar/UserRole/EditForm"
import AddDomaindata from "./pages/NavBar/UserDomainList/Domaindata";
import EditDomaindata from "./pages/NavBar/UserDomainList/EditDomaindata";
import Plans from "./pages/NavBar/Subscriptions/Plans";
import Plandetails from "./pages/NavBar/Subscriptions/DetailPlan";
import GoogleConsole from "./pages/NavBar/GoogleConsole/Console";
import OrganicsearchClicks from "./pages/NavBar/Googleanalytics/OrganicsearchClicks";
import TopPerformingPages from "./pages/NavBar/GoogleConsole/TopPerformingPages";
import SearchQueries from "./pages/NavBar/GoogleConsole/SearchQueries";
import Errorpage from "./pages/NavBar/Errorpage/Errorpage";
import Errorpages from "./pages/NavBar/Errorpage/Errorpages";
import ConfirmError from "./pages/NavBar/Errorpage/ConfirmError";
import SiteMapGenerator from "./pages/NavBar/SiteMap/Generate";
import TopactionItems from "./pages/NavBar/topactionitems/TopactionItems";
import BrandMentions from "./pages/NavBar/KeyWords/BrandMentions";
import SiteAuditConfig from "./pages/NavBar/Settings/SiteAuditConfig";
import Topactionsetting from "./pages/NavBar/Settings/Topactionsetting"
import UserDomainsecList from "./pages/NavBar/UserDomainSection/UserDomainsecList";
import DomainAddForm from "./pages/NavBar/UserDomainSection/DomainAddForm"
import EditDomainsecdata from "./pages/NavBar/UserDomainSection/EditDomainsecdata"
import CompetitorSettings from "./pages/NavBar/CompetitorSettings/UserDomainsecList";
import AddCompetitorSettings from "./pages/NavBar/CompetitorSettings/DomainAddForm";
import Editcompetitorsecdata from "./pages/NavBar/CompetitorSettings/EditDomainsecdata";
import PageLevelSetting from "./pages/NavBar/Settings/PageLevelSetting";
import SpellcheckerSettings from "./pages/NavBar/SpellcheckerSettings/UserDomainsecList";
import AddSpellcheckerSettings from "./pages/NavBar/SpellcheckerSettings/DomainAddForm";
import RegionallevelSetting from "./pages/NavBar/Settings/RegionallevelSetting";
import Addkeyword from "./pages/NavBar/KeyWords/KeywordAddForm"
import KeywordEditForm from "./pages/NavBar/KeyWords/KeywordEditForm"
import DoaminGoogleanalytics from "./pages/NavBar/UserDomainList/Googleanalytics"
import DomainownershipData from "./pages/NavBar/UserDomainList/Domainownership"
import Pageindexlist from "./pages/NavBar/Pageindex/Pageindexlists"
import Accessbilityreportlist from "./pages/NavBar/AccessbilityReport/List"
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/400-italic.css"; // Specify weight and style
import axios from "../src/pages/login/AxiosMiddleWare";
import UserExperienceSettings from "./pages/NavBar/Settings/UserExperience";
import Customreport from "./pages/NavBar/Customreport";
import AccessibilitySummary from "./pages/NavBar/AccessibilitySummary/AccessibilitySummary";
// import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import Tableuselayouts from "./pages/NavBar/UseLayout";
// // import useRedirectToHomeOnReload from './useRedirectToHomeOnReload';
// import { useDispatch } from "react-redux";
// import { SetUserToken } from "./Store/UserTokenSlice";
// import { SetAuthToken } from "./Store/ApiAuthKey";
// import Uselayout from "./pages/NavBar/UseLayout";

// const logout = () => {
//   localStorage.removeItem("authToken");
//   localStorage.removeItem("tabCount");
//   window.location.href = "/login";
// };
const App = () => {

  // const dispatch = useDispatch();

  // const esodata = useSelector((state) => state);
  // const ApiToken = esodata.apiauthkey.value;

 
  // Uselayout(logout);

  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          {/* <Route
          exact
          path="/uselayout"
          element={
            <>
         
              <Tableuselayouts />
            </>
          }
          /> */}
          <Route
            exact
            path="/Userdata"
            element={
              <>
                {/* <LoginMiddleWare /> */}
                {/*<Home />*/}
                {/* <Sidebar /> */}
                <Userdata />
              </>
            }
          />
          <Route
            exact
            path="/Domaindata"
            element={
              <>
                {/* <LoginMiddleWare /> */}
                {/* <Home /> */}
                {/* <Sidebar /> */}
                <Domaindata />
              </>
            }
          />
          <Route
            exact
            path="/Domainownership"
            element={
              <>
                {/* <LoginMiddleWare /> */}
                {/* <Home /> */}
                {/* <Sidebar /> */}
                <Domainownership />
              </>
            }
          />
          <Route
            exact
            path="/Googleanalytics"
            element={
              <>
                {/* <LoginMiddleWare /> */}
                <Home />
                {/* <Sidebar /> */}
                <Googleanalytics />
              </>
            }
          />
          <Route
            exact
            path="/"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <MainDashboard />
              </>
            }
          />
          <Route
            exact
            path="/analysis-summary"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Auditsummary />
              </>
            }
          />
          <Route
            exact
            path="/plagiarism-list"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Plagiarisamlist />
              </>
            }
          />
          <Route
            exact
            path="/plagiarisam-detail/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Plagiarisamdetail />
              </>
            }
          />
          <Route
            exact
            path="/plagiarisam-scan-detail/:detailid/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Plagiarisamscandetail />
              </>
            }
          />
          <Route
            exact
            path="/schema-analysis"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Schemaanalysis />
              </>
            }
          />
          <Route
            exact
            path="/schema-detail/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Schemadetail />
              </>
            }
          />
          <Route
            exact
            path="/content-detail/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <ContentauditDetails />
              </>
            }
          />
          <Route
            exact
            path="/source-code-list"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Codeauditlist />
              </>
            }
          />
          <Route
            exact
            path="/source-code-detail/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <CodeauditDetail />
              </>
            }
          />
          <Route
            exact
            path="/internal-link-checker"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Linkchecklists />
              </>
            }
          />

          <Route
            exact
            path="/external-link-checker"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <ExternalLinkLists />
              </>
            }
          />

          <Route
            exact
            path="/internal-link-checker/source/:jobid"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <InternallinkSource />
              </>
            }
          />

          <Route
            exact
            path="/internal-link-checker/out-bound/:jobid"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <InternallinkOutbound />
              </>
            }
          />

          <Route
            exact
            path="/site-audit/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Siteanalysis />
              </>
            }
          />
          <Route
            exact
            path="/page-health/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Sitepageanalysis />
              </>
            }
          />
          <Route
            exact
            path="/competitor-test/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Sitecompetoranalysis />
              </>
            }
          />
          <Route
            exact
            path="/onpage-crawl-data/:id/:onid"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Siteonanalysis />
              </>
            }
          />
          {/* <Route
            exact
            path="/pagehealth-detail/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Pagehealthdetail />
              </>
            }
          /> */}
          <Route
            exact
            path="/pagehealth-detail/:id/:onpageid"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Pagehealthdetail />
              </>
            }
          />
          <Route
            exact
            path="/image-detail/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Imagedetails />
              </>
            }
          />
          <Route
            exact
            path="/missing-image-detail/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Missingimagedetails />
              </>
            }
          />
          {/* <Route
            exact
            path="/target-keywords"
            element={
              <>
                <Sidebar />
                <Home />
                <Tarketkeywordslist />
              </>
            }
          /> */}

          <Route
            exact
            path="/keywords"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <KeywordsList />
              </>
            }
          />
          <Route
            exact
            path="/add-campaign"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <AddcampaignList />
              </>
            }
          />
          <Route
            exact
            path="/edit-campaign/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <AddcampaignList />
              </>
            }
          />
          <Route
            exact
            path="/add-brands"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Addbrands />
              </>
            }
          />
          <Route
            exact
            path="/performance-list"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Performancelist />
              </>
            }
          />
          <Route
            exact
            path="/performance-detail/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Performancedetail />
              </>
            }
          />
           
          <Route
            exact
            path="/performance/leverage/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <PerformnceLevarageList />
              </>
            }
          />
          <Route
            exact
            path="/performance/render-blocking/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <PerformnceRenderBlockingList />
              </>
            }
          />
          <Route
            exact
            path="/regression-list"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Regressiontest />
              </>
            }
          />
          <Route
            exact
            path="/spell-checker-list"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Spellcheckerlist />
              </>
            }
          />
          <Route
            exact
            path="/spell-checker-detail/:detailid/:jobid"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <SpellcheckerDetail />
              </>
            }
          />
          <Route
            exact
            path="/accessibility-score"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <AccessibilityList />
              </>
            }
          />
          <Route
            path="/accessbility-score-detail/:id/:jobid"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <AccessbilityScoreDetails />
              </>
            }
          />

          <Route
            path="/google-login"
            element={
              <>
                <Sidebar />
                <Home />
                <GoogleLoginCom />
              </>
            }
          />

          <Route
            path="/apple-login"
            element={
              <>
                <Sidebar />
                <Home />
                <AppleLoginCom />
              </>
            }
          />
          <Route
            exact
            path="/searchkey-volume-list"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Searchkeyvolumelist />
              </>
            }
          />
          <Route
            exact
            path="/competitor-ranking-list"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Competitorlist />
              </>
            }
          />
          <Route
            exact
            path="/missed-opportunity-list"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <MissedOpportunity />
              </>
            }
          />
          <Route
            exact
            path="/ranking-summary"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Rankingsummary />
              </>
            }
          />
          <Route
            exact
            path="/ranking-summary/:idval"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Rankingsummary />
              </>
            }
          />
          <Route
            exact
            path="/login"
            element={
              <>
                <LoginPage />
              </>
            }
          />
          <Route
            exact
            path="/password-reset"
            element={
              <>
                <ResetPassword />
              </>
            }
          />
          <Route
            exact
            path="/reset-success"
            element={
              <>
                <ResetSuccess />
              </>
            }
          />
          <Route
            exact
            path="/reset-password/:uid/:utoken"
            element={
              <>
                <ResetPasswordConformation />
              </>
            }
          />
          <Route
            exact
            path="/updated-password/:uid/:utoken"
            element={
              <>
                <ApprovePassword />
              </>
            }
          />
            <Route
            exact
            path="/confirm-url/:uid"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <ConfirmError/>
              </>
            }
          />

          {/* <Route
          exact
          path="/backLinks"
          element={
            <>
          <Home />
          <Sidebar />
          <BackLinks />
            </>
          }
        /> */}
          {/* <Route
          exact
          path="/new-links"
          element={
            <>
          <Home />
          <Sidebar />
          <NewLinks />
            </>
          }
        /> */}
          <Route
            exact
            path="/ranking-summary/detail/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <RankingListDetail />
              </>
            }
          />
          <Route
            exact
            path="/visibility-score-detail"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <VisibilityScoreDetail />
              </>
            }
          />
          <Route
            exact
            path="/linked-domain"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <LinkedDomainList />
              </>
            }
          />
          <Route
            exact
            path="/new-lost-link"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Linklist />
              </>
            }
          />
          <Route
            exact
            path="/backLinks"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <BackLinksIndex />
              </>
            }
          />

          <Route
            exact
            path="/change-password"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <ChangePassword />
              </>
            }
          />
          <Route
            exact
            path="/my-account"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Myaccount />
              </>
            }
          />
          <Route
            exact
            path="/domain-list"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <DomainList />
              </>
            }
          />
             <Route
            exact
            path="/user-list"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <UserRole />
              </>
            }
          />
            <Route
            exact
            path="/add-role"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <AddForm />
              </>
            }
          />
            <Route
            exact
            path="/edit-role/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <EditForm />
              </>
            }
          />
          
          <Route
            exact
            path="/add-domain-list"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <AddDomaindata />
              </>
            }
          />
          <Route
            exact
            path="/edit-domain-list/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <EditDomaindata />
              </>
            }
          />
           <Route
            exact
            path="/domain-google_analytics"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <DoaminGoogleanalytics />
              </>
            }
          />
          <Route
            exact
            path="/domain-list/domain-ownership"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <DomainownershipData />
              </>
            }
          />
          <Route
            exact
            path="/email-verification"
            element={
              <>
                <CheckMail />
              </>
            }
          />
          <Route
            exact
            path="organic-search-traffic"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <OrganicsearchClicks />
              </>
            }
          />

          <Route
            exact
            path="/google-console/brand-vs-non-brand"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <GoogleConsole />
              </>
            }
          />
          <Route
            exact
            path="/google-console/search-queries"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <SearchQueries />
              </>
            }
          />

          <Route
            exact
            path="/google-console/top-performing-pages"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <TopPerformingPages />
              </>
            }
          />

          <Route
            exact
            path="/google-console/search-queries"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <SearchQueries />
              </>
            }
          />

          <Route
            exact
            path="/subscription"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Plans />
              </>
            }
          />

          <Route
            exact
            path="/subscription/auth-check"
            element={
              <>
                <LoginMiddleWare />

                <Plans />
              </>
            }
          />

          <Route
            exact
            path="*"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar /> <Home />
                <Errorpage />
              </>
            }
          />
    <Route
            exact
            path="errorpages"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar /> <Home />
                <Errorpages />
              </>
            }
          />
           <Route
            exact
            path="login-errorpages"
            element={
              <>
                <Errorpages />
              </>
            }
          />
          <Route
            exact
            path="/sitemap-generator"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <SiteMapGenerator />
              </>
            }
          />
          <Route
            exact
            path="/top-action-item"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <TopactionItems />
              </>
            }
          />

          <Route
            exact
            path="/brand-mentions"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <BrandMentions />
              </>
            }
          />

          <Route
            exact
            path="/site-audit-config"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <SiteAuditConfig/>
         
              </>
            }
          />
           <Route
            exact
            path="/top-action-severity"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Topactionsetting/>
         
              </>
            }
          />
            <Route
            exact
            path="/page-level-setting"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <PageLevelSetting/>
         
              </>
            }
          />
           <Route
            exact
            path="/domain-section-list"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <UserDomainsecList/>
         
              </>
            }
          />
           <Route
            exact
            path="/edit-domainsec-list/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <EditDomainsecdata/>
         
              </>
            }
          />
            <Route
            exact
            path="/add-section-list"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <DomainAddForm/>
         
              </>
            }
          />
            <Route
            exact
            path="/competitor-settings-list"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <CompetitorSettings/>
         
              </>
            }
          />
                <Route
            exact
            path="/add-competitor-list"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <AddCompetitorSettings/>
         
              </>
            }
          />
              <Route
            exact
            path="/edit-competitordomain-list/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Editcompetitorsecdata/>
         
              </>
            }
          />

             <Route
            exact
            path="/user-experience-score"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <UserExperienceSettings/>
         
              </>
            }
          />
            <Route
            exact
            path="/spell-checker-settings"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <SpellcheckerSettings/>
         
              </>
            }
          />
                <Route
            exact
            path="/add-spellchecker-list"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <AddSpellcheckerSettings/>
         
              </>
            }
          />
          <Route
            exact
            path="/regional-level-settings"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <RegionallevelSetting/>
              </>
            }
          />
           <Route
            exact
            path="/add-keyword"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Addkeyword/>
              </>
            }
          />       
              <Route
            exact
            path="/edit-keyword/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <KeywordEditForm />
              </>
            }
          /> 
          <Route
            exact
            path="/page-index-report"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Pageindexlist />
              </>
            }
          /> 
          <Route
            exact
            path="/accessbility-report-list"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Accessbilityreportlist />
              </>
            }
          /> 
             <Route
            exact
            path="/accessbility-report-detail/:id/:jobid"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Accessbilitydetail />
              </>
            }
          />
              <Route
            exact
            path="/accessbility-file"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Accessbilityfile />
              </>
            }
          />
                <Route
            exact
            path="/fileacessbility-detail/:jobid"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Accessbilityfiledetail />
              </>

            }
          />
            <Route
            exact
            path="/custom-report"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Customreport />
              </>
            }
          />
              <Route
            exact
            path="/accessibility-summary"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <AccessibilitySummary />
              </>
            }
          />
             <Route
            exact
            path="/standard-report"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Standardreport />
              </>
            }
          />
           <Route
            exact
            path="/plan-details/:id"
            element={
              <>
                <LoginMiddleWare />
                <Sidebar />
                <Home />
                <Plandetails />
              </>
            }
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;
