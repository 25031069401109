import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import "./Siteauditstyle.css";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Loader from "../CommonLoader";
import { Commonfun } from './../Commonfun';
export default function Pagehealthdetail() {
  const [cardData, setCardData] = useState([]);
  const [sitecardData, setSitecardData] = useState([]);
  const [internalcardData, setInternalcardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalpercecntwords, setTotalpercentwords] = useState("");
  const [queryurl, setQueryurl] = useState("");
  const [value, setValue] = useState("1");
  const [rescardData, setRescardData] = useState([]);
  const [jobDate, setjobDate] = useState("");
  const esodata = useSelector((state) => state);
  const [autoheightval,SetAutoheightval]=useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  const navigate = useNavigate();

  const ApiToken=esodata.apiauthkey.value
  let querystring = useParams();
  let plag_id = querystring.id;
  let onpageid = querystring.onpageid;
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const MyCustomNoRowsOverlay = () => (
    <img src="/no-items-found.jpg" alt="no-item" />
  );
  let detailurl = `${process.env.REACT_APP_DOMAIN}plagiarisam-scan-detail/`;

  useEffect(() => {
    callUserdataApi();
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const callUserdataApi = async () => {
    setLoading(true);
    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}technical/pagehealth/detail/${plag_id}/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}&job_id=${onpageid}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if(error?.code =="ERR_NETWORK"){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });

    const dataval = datavalue?.data;
    setQueryurl(dataval?.url);
    setjobDate(moment(dataval?.job_date).format("MMM DD,YYYY "));
    setCardData(dataval);
   
    setSitecardData(dataval?.page_metrics_list);
    setInternalcardData(dataval?.internal_link_list);
    if(dataval?.seo_metrics_list.length>0){
      setRescardData(dataval?.seo_metrics_list)
      SetAutoheightval(false)
    }
    else{
      setRescardData("")
      SetAutoheightval(true)
    }
    setLoading(false);
  };
 
  const rows = rescardData ?? [];
  const rows1 = sitecardData ?? [];
  const rows2 = internalcardData ?? [];

  const SeoMetricsArray=[
    
    {
    key:'no_index_robot',
    value:'The web pages must be accessible to Search engines for better SEO results. This metric will check if the web page is accessible to search engines'
  },
  {
    key:'missing_canonical',
    value:'Canonical URLs inform search engines that a specific URL represents the source of the content on a web page. This metric will check for a canonical URL or tag on the web page.or tag on the web page'
  },
  {
    key:'missing_titles',
    value:'The title tag specifies the web page title. This metric will show the total number of webpages that have a missing title.'
  },
  {
    key:'duplicate_h1_tags',
    value:'H1 tags are used to define important HTML headings. This metric will show the total number of webpages that have the same H1 tags.'
  },
  {
    key:'missing_h1_tags',
    value:'H1 tags are used to define important HTML headings. This metric will show the total number of webpages that have missing H1 tags.'
  },
  {
    key:'hreflang',
    value:"Hreflang is an HTML attribute used to specify a web page's language and geographic target. This metric displays the total count of webpages using the hreflang attribute."
  },
  {
    key:'pagination_prev_or_next',
    value:"This metrics displays the count of Page URLs which have next and previous URLs"
  },
  {
    key:'duplicate_titles',
    value:"The title tag specifies the web page title. This metric will show the total number of web pages with the same title."
  },
  {
    key:'duplicate_descriptions',
    value:"The description tag specifies the description of the web page. This metric populates the total number of webpages that have the same description."
  },
  {
    key:'missing_descriptions',
    value:"The description tag specifies the description of the web page. This metric populates the total number of web pages that have a missing description."
  },
  {
    key:'missing_alt_tags',
    value:"The ALT tag describes the presence or function of an image on a web page. This metric will show the total count of images on a web page that have missing ALT tags."
  },
  {
    key:'h1_tags_available',
    value:"H1 tags are used to define important HTML headings. This metric will show the total number of webpages that have an H1 tag. will show the total number of webpages that have the same H1 tags. that have an H1 tag"
  },
  {
    key:'images_available',
    value:"Images Available	This metric provides the total number of images available in the domain."
  },
  {
    key:'server_error_5xx_link',
    value:"The 5xx status code means that the server failed to complete a request. This metric displays the count of web pages that return a 5xx status code."
  },
  {
    key:'broken_links_4xx_link',
    value:"The 4xx status code means that the request for a web page or other resource contains bad syntax. This metric displays the count of web pages that return a 4xx status code."
  }


];

function findObjectByKeyValue(array, key, value) {
  return array.find(obj => obj[key] === value);
}


  const columns = [
    {
      field: "seo_metrics",
      headerName: "SEO Metricss",
      description:'On-page SEO elements are present on the website. are present on the website.',
      width: 500,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {

        var ToolTipTitle='';

        const result = findObjectByKeyValue(SeoMetricsArray, 'key',cardData.row.seo_metrics);

        if(result!==undefined)
        {
          ToolTipTitle=result.value
        }

     

        if(ToolTipTitle!='')
        {
          return (
            <Tooltip title={ToolTipTitle}>
            <p>{cardData.row.seo_metrics}</p>
            </Tooltip>
          );
        }
        
        else{

          return (
            <p>{cardData.row.seo_metrics}</p>
          );

        }
       
      },
    },
    {
      field: "priority",
      headerName: "Priority",
      description:'SEO and Site parameters can be prioritized and categorized according to your organization’s marketing strategies in the site audit settings',
      width: 200,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {
        return (
          <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
        );
      },
    },
    {
      field: "occurences",
      headerName: "Occurrences",
      description:'The total number of specific metrics present on the website.',
      width: 200,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
  ];
 
  const column1 = [
    {
      field: "page_metrics",
      headerName: "Page Metrics",
      description:'Website metrics are present on the website.',
      width: 500,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {

        var ToolTipTitle='';

        const result = findObjectByKeyValue(SeoMetricsArray, 'key',cardData.row.page_metrics);

        if(result!==undefined)
        {
          ToolTipTitle=result.value
        }

     

        if(ToolTipTitle!='')
        {
          return (
            <Tooltip title={ToolTipTitle}>
            <p>{cardData.row.page_metrics}</p>
            </Tooltip>
          );
        }
        
        else{

          return (
            <p>{cardData.row.page_metrics}</p>
          );

        }
       
      },
    },
    {
      field: "priority",
      headerName: "Priority",
      description:'SEO and Site parameters can be prioritized and categorized according to your organization’s marketing strategies in the site audit settings',
      width: 200,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {
        return (
          <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
        );
      },
    },
    {
      field: "occurences",
      headerName: "Occurrences",
      description:'The total number of specific metrics present on the website.',
      width: 200,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
  ];
  const column2 = [
    {
      field: "internal_links",
      headerName: "Internal Metrics",
      description:'It displays server error links (5xx) and broken links (4xx) on the website.',
      width: 500,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {

        var ToolTipTitle='';

        const result = findObjectByKeyValue(SeoMetricsArray, 'key',cardData.row.internal_links);

        if(result!==undefined)
        {
          ToolTipTitle=result.value
        }

     

        if(ToolTipTitle!='')
        {
          return (
            <Tooltip title={ToolTipTitle}>
            <p>{cardData.row.internal_links}</p>
            </Tooltip>
          );
        }
        
        else{

          return (
            <p>{cardData.row.internal_links}</p>
          );

        }
       
      },
    },
    {
      field: "priority",
      headerName: "Priority",
      description:'SEO and Site parameters can be prioritized and categorized according to your organization’s marketing strategies in the site audit settings',
      width: 200,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {
        return (
          <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
        );
      },
    },
    {
      field: "occurences",
      headerName: "Occurrences",
      description:'The total number of specific metrics present on the website.',
      width: 200,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
  ];
  var state = {
    value: totalpercecntwords,
  };
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "#fe874e";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  const pdfdown = async () =>{
    setLoading(true);
    try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}technical/pagehealth/detail/${plag_id}/?user_domain_id=${esodata.domaindata.value.header_selected}&is_format=pdf`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
             
                var filedata=await Commonfun("pageHealthdetail","pdf", ApiToken,esodata.domaindata.value.header_selected);

                link.download = filedata;
                link.click(); 

                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              toast.error("No Data", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                console.error('Failed to fetch PDF:', response.status, response.statusText);
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);
  }
  const csvdown = async () =>{
    setLoading(true);
    try{
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/pagehealth/detail/${plag_id}/?user_domain_id=${esodata.domaindata.value.header_selected}&is_format=csv`, {

                method: 'GET',
                headers: {
                    'Content-Type': 'application/csv',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                var filedata=await Commonfun("pageHealthdetail","csv", ApiToken,esodata.domaindata.value.header_selected);

                link.download = filedata;
                link.click(); 


                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              toast.error("No Data", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                console.error('Failed to fetch PDF:', response.status, response.statusText);
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);
  }
  return (
    
    <div className="cnt_audit_outer_wrap">
      {loading === true ? <Loader /> : null}
        <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
           <Helmet>
                <meta charSet="utf-8" />
                <title>Page Health Detail </title>
            </Helmet>
            {/* <div
        style={{
          width: "calc(100% - 40px)",
          marginRight: "40px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      > */}
          <div className="cnt_audit_outer_wrap">
          <div
        style={{
          width: "calc(100% - 40px)",
          marginRight: "40px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      > 
      <div className="cnt_hdr_top_wrap">
        <div className="cnt_hdr_top_lft_wrap">
          <h2>Page Health Checker Detailed Report For</h2>
          <p><b>{queryurl}</b></p>
          <p>as of {jobDate}</p>
        </div>
        <div className="cnt_hdr_top_rgt_wrap">
        <img src={pdficon} alt="pdf icon" onClick={pdfdown}/>
          <img src={csvicon} alt="csv icon" onClick={csvdown} />
        </div>
      </div>
      </div>
      </div>
      {/* </div> */}

      <div className="cnt_hdr_content">
        <div className="box_wrap">
          <div>
            <h1>Pagehealth Checker Report For</h1>
            <p></p>
          </div>
          <div className="cnt_hdr_content_box_outer">
           
            <div className="pagehealth_missing_waring_wrap plagiarisam_chart_outer">
              <div className="plagiarisam_chart">
                <LiquidFillGauge
                  style={{ margin: "0 auto" }}
                  width={84}
                  height={84}
                  value={cardData.high_impact_percentage}
                  percent="%"
                  textSize={1}
                  textOffsetX={0}
                  textOffsetY={0}
                  riseAnimation
                  waveAnimation
                  waveFrequency={2}
                  waveAmplitude={1}
                  gradient
                  gradientStops={gradientStops}
                  circleStyle={{
                    fill: textfillColor,
                  }}
                  waveStyle={{
                    fill: fillColor,
                  }}
                  textStyle={{
                    fill: color("#FFFFFF").toString(),
                    fontFamily: "Arial",
                  }}
                  waveTextStyle={{
                    fill: color("#fff").toString(),
                    fontFamily: "Arial",
                  }}
                />
              </div>
              <p> High Impact Issue Occurrences - {cardData?.high_impact_issue}</p>
            </div>
            <div className="pagehealth_missing_waring_wrap plagiarisam_chart_outer">
              <div className="plagiarisam_chart">
                <LiquidFillGauge
                  style={{ margin: "0 auto" }}
                  width={84}
                  height={84}
                  value={cardData.medium_impact_percentage}
                  percent="%"
                  textSize={1}
                  textOffsetX={0}
                  textOffsetY={0}
                  riseAnimation
                  waveAnimation
                  waveFrequency={2}
                  waveAmplitude={1}
                  gradient
                  gradientStops={gradientStops}
                  circleStyle={{
                    fill: textfillColor,
                  }}
                  waveStyle={{
                    fill: fillColor,
                  }}
                  textStyle={{
                    fill: color("#FFFFFF").toString(),
                    fontFamily: "Arial",
                  }}
                  waveTextStyle={{
                    fill: color("#fff").toString(),
                    fontFamily: "Arial",
                  }}
                />
              </div>
              <p>Medium Impact Issue Occurrences - {cardData?.medium_impact_issue}</p>
            </div>
            <div className="pagehealth_missing_waring_wrap plagiarisam_chart_outer">
              <div className="plagiarisam_chart">
                <LiquidFillGauge
                  style={{ margin: "0 auto" }}
                  width={84}
                  height={84}
                  value={cardData.low_impact_percentage}
                  percent="%"
                  textSize={1}
                  textOffsetX={0}
                  textOffsetY={0}
                  riseAnimation
                  waveAnimation
                  waveFrequency={2}
                  waveAmplitude={1}
                  gradient
                  gradientStops={gradientStops}
                  circleStyle={{
                    fill: textfillColor,
                  }}
                  waveStyle={{
                    fill: fillColor,
                  }}
                  textStyle={{
                    fill: color("#FFFFFF").toString(),
                    fontFamily: "Arial",
                  }}
                  waveTextStyle={{
                    fill: color("#fff").toString(),
                    fontFamily: "Arial",
                  }}
                />
              </div>
              <p>Low Impact Issue Occurrences - {cardData?.low_impact_issue}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="tabs_outer_wrap tabs_outer_wrap_tab_btm_rem">
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="SEO Metrics" value="1" />
              <Tab label="Page Metrics" value="2" />
              <Tab label="Internal Links" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className="data_table" style={{ width: "100%" }}>
              <div style={{ height: 550, width: "100%" }}>
                <DataGrid
                    className="data_table"
                  sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                    ".MuiDataGrid-virtualScroller": {
                      height: "auto !important",
                      overflowY: "auto",
                    },
                    "& .MuiDataGrid-cell:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      zIndex: 999,
                    },
                    "& .MuiDataGrid-columnHeader:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      border: "none",
                      zIndex: 999,
                    },
                  }}
                  autoHeight={autoheightval?true:false}
                  getRowHeight={() => 'auto'}
                  getRowId={(row) => row.seo_metrics}
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar
                  }}
                  slotProps={{
                    toolbar: {
                  
                      showQuickFilter: true,
                    
                    },
                  }}
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10, 30, 50, 75, 100]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2">
            <div className="data_table" style={{ width: "100%" }}>
              <div style={{ height: 750, width: "100%" }}>
                <DataGrid
                    className="data_table"
                  sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                  }}
                  autoHeight
                  getRowHeight={() => 'auto'}
                  getRowId={(row) => row.page_metrics}
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar
                  }}
                  slotProps={{
                    toolbar: {
                  
                      showQuickFilter: true,
                    
                    },
                  }}
                  rows={rows1}
                  columns={column1}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                   pageSizeOptions={[5,10,25]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value="3">
            <div className="data_table" style={{ width: "100%" }}>
              <div style={{ height: 750, width: "100%" }}>
                <DataGrid
                    className="data_table"
                  sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                  }}
                  autoHeight
                  getRowHeight={() => 'auto'}
                  getRowId={(row) => row.internal_links}
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar
                  }}
                  slotProps={{
                    toolbar: {
                  
                      showQuickFilter: true,
                    
                    },
                  }}
                  rows={rows2}
                  columns={column2}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                   pageSizeOptions={[5,10,25]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                />
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </div>
      <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}
