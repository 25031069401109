// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.extract code {
   color: #eb1717;
   font-size: 18px;
}
 .cnt_hdr_content_table li.info {
    font-weight: 600;
     font-size: 14px;
     letter-spacing: 0.9px;
    }
    .cnt_hdr_content_table {
     background: #f0f8ff14 !important;
   }


   .code_audit_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(2)
   {
      display: block;
      padding-left: 85px;

   }
   .cnt_hdr_content_table .box_wrap {
      word-wrap: break-word;
  }
  .post__content #results>ol>li.w3_error strong, .h_errors {
color:#ff0202;
font-weight: 800;
}
.post__content #results>ol>li.w3_warning strong,.h_warn{
   color:#ff5300;
   font-weight: 800;
}
.h_ferr{
   font-weight: 800;
}
.fatal_errore{
   background-color: #fe4e58  !important;
   color: #000000
}
.err_inner{
   background-color: #f30000  !important;
   color: #000000 
}
.warning_erre{
   background-color: #fdfe4e  !important;
   color: #000000 
}`, "",{"version":3,"sources":["webpack://./src/pages/NavBar/codeaudit/codeauditstyle.css"],"names":[],"mappings":"AAAA;GACG,cAAc;GACd,eAAe;AAClB;CACC;IACG,gBAAgB;KACf,eAAe;KACf,qBAAqB;IACtB;IACA;KACC,gCAAgC;GAClC;;;GAGA;;MAEG,cAAc;MACd,kBAAkB;;GAErB;GACA;MACG,qBAAqB;EACzB;EACA;AACF,aAAa;AACb,gBAAgB;AAChB;AACA;GACG,aAAa;GACb,gBAAgB;AACnB;AACA;GACG,gBAAgB;AACnB;AACA;GACG,qCAAqC;GACrC;AACH;AACA;GACG,qCAAqC;GACrC;AACH;AACA;GACG,qCAAqC;GACrC;AACH","sourcesContent":[".extract code {\r\n   color: #eb1717;\r\n   font-size: 18px;\r\n}\r\n .cnt_hdr_content_table li.info {\r\n    font-weight: 600;\r\n     font-size: 14px;\r\n     letter-spacing: 0.9px;\r\n    }\r\n    .cnt_hdr_content_table {\r\n     background: #f0f8ff14 !important;\r\n   }\r\n\r\n\r\n   .code_audit_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(2)\r\n   {\r\n      display: block;\r\n      padding-left: 85px;\r\n\r\n   }\r\n   .cnt_hdr_content_table .box_wrap {\r\n      word-wrap: break-word;\r\n  }\r\n  .post__content #results>ol>li.w3_error strong, .h_errors {\r\ncolor:#ff0202;\r\nfont-weight: 800;\r\n}\r\n.post__content #results>ol>li.w3_warning strong,.h_warn{\r\n   color:#ff5300;\r\n   font-weight: 800;\r\n}\r\n.h_ferr{\r\n   font-weight: 800;\r\n}\r\n.fatal_errore{\r\n   background-color: #fe4e58  !important;\r\n   color: #000000\r\n}\r\n.err_inner{\r\n   background-color: #f30000  !important;\r\n   color: #000000 \r\n}\r\n.warning_erre{\r\n   background-color: #fdfe4e  !important;\r\n   color: #000000 \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
