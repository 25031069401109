// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stacked-bar-graph .bardata {
   background:#15122e;
}
.stacked-bar-graph span {
   display: inline-block!important;
   height: 100%;
   box-sizing: border-box;
}
.stacked-bar-graph {
   width: 100%;
   height: 20px;
   color: #15122e;
}
.stacked-bar-graphs .bardata {
   background: #F7B334;
}
.stacked-bar-graphs span {
   display: inline-block!important;
   height: 100%;
   box-sizing: border-box;
}
.stacked-bar-graphs {
   width: 100%;
   height: 20px;
   color: #F7B334;
}
.css-1hsc7hf-MuiDataGrid-root .MuiDataGrid-cellContent {
    overflow: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/NavBar/Backllinks/linkeddomainstyle.css"],"names":[],"mappings":"AAAA;GACG,kBAAkB;AACrB;AACA;GACG,+BAA+B;GAC/B,YAAY;GACZ,sBAAsB;AACzB;AACA;GACG,WAAW;GACX,YAAY;GACZ,cAAc;AACjB;AACA;GACG,mBAAmB;AACtB;AACA;GACG,+BAA+B;GAC/B,YAAY;GACZ,sBAAsB;AACzB;AACA;GACG,WAAW;GACX,YAAY;GACZ,cAAc;AACjB;AACA;IACI,0BAA0B;AAC9B","sourcesContent":[".stacked-bar-graph .bardata {\n   background:#15122e;\n}\n.stacked-bar-graph span {\n   display: inline-block!important;\n   height: 100%;\n   box-sizing: border-box;\n}\n.stacked-bar-graph {\n   width: 100%;\n   height: 20px;\n   color: #15122e;\n}\n.stacked-bar-graphs .bardata {\n   background: #F7B334;\n}\n.stacked-bar-graphs span {\n   display: inline-block!important;\n   height: 100%;\n   box-sizing: border-box;\n}\n.stacked-bar-graphs {\n   width: 100%;\n   height: 20px;\n   color: #F7B334;\n}\n.css-1hsc7hf-MuiDataGrid-root .MuiDataGrid-cellContent {\n    overflow: unset !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
