import React, { useState, useRef  } from 'react';
import './login.css'
import GoogleLogo from './Asset/google.png';
import AppleLogo from './Asset/apple-logo.png';
import Logo from "../NavBar/Asset/New_Assert/ESO_Logo.png";
import PasswordRestGif from './Asset/On_Board/resetpassword.gif';
import axios from "axios";
import Loader from "../NavBar/CommonLoader";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PasswordReset=()=>{



  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
    const emailPhonepsrstRef = useRef(null);
    const [errors, setErrors] = useState({
        emailPhoneerr: '',
      });
    const [formData, setFormData] = useState({
        emailPhone: '',
      });
      const SmthandleChangerst = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      
        // Clear the specific field error when the user starts typing
        setErrors({
          ...errors,
          [name]: '',
        });
      };
    const PasswordReset =async (e) => {
        e.preventDefault();

        // Validation logic
        const { emailPhonepsrst} = formData;
        const phoneNumberRegex = /^[0-9]{10}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      // // Get the last 4 characters
      // const lastFourCharacters = emailPhonepsrst.substring(emailPhonepsrst.length - 4);
        const newErrors = {};
        var Errorcheck=0;
       
        if (!emailPhonepsrst || emailPhonepsrst.length === 0) {
          newErrors.emailPhoneerr = "Please enter your email address.";
          Errorcheck=1
          // Focus on the email/phone input field if there's an error
          emailPhonepsrstRef.current.focus();
        } else if (!emailRegex.test(emailPhonepsrst)) {
          newErrors.emailPhoneerr = "Please enter a valid email address.";
          Errorcheck=1;
          // Focus on the email/phone input field if there's an error
          emailPhonepsrstRef.current.focus();
        }
        setErrors(newErrors);

        if(Errorcheck===0)
        {

          setLoading(true)

          try{

            const Response = await axios.post(
              `${process.env.REACT_APP_API_URL}accounts/password_reset_link/`,
              {
                email: emailPhonepsrst,
                redirect_url:process.env.REACT_APP_DOMAIN+'reset-password'
              },
              // {
              //   headers: {
              //     Authorization: `${ApiToken}`,
              //   },
              // }       
            );

            if(Response.data)
            {
               if(Response.data.status==='success')
               {
                setFormData({})

                var inputElement = document.querySelector('[name="emailPhonepsrst"]');
                inputElement.value=''

                toast.success('Password reset link has been sent to your given email address. Kindly check!', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });

               }
            }
  
         

          }catch(err)
          {

    
            var valuesForKey=[];
            var valuesForKeyN=[];
            var valuesForKeyE=[];

            if(err.response.data.error_list)
            {
               valuesForKey = err.response.data.error_list.map(obj => String(obj['error']));
            }
            if(err.response.data.non_field_errors)
            {
               valuesForKeyN = err.response.data.non_field_errors
            }
            if(err.response.data.email)
            {
              valuesForKeyE = err.response.data.email
            }
             
            valuesForKey=valuesForKey.concat(valuesForKeyN);
            valuesForKey=valuesForKey.concat(valuesForKeyE);
            valuesForKey=valuesForKey.join(',')
            setLoading(false)
            toast.error(valuesForKey, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          }
         

          setLoading(false)


        }




      };

    return (
        <>
              {loading === true ? <Loader /> : null}
    <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
            <div className='tab_otr_outer reset_page_container'>
    <div className='tab_head_container_lft'>
    <div className='tab_head_innr_container'>
    <img src={Logo} className="login_tab_img" alt="ESO logo"/>
        <div className='tab_head_container_outr rest_container'>
          <h1 className='tab_header'>Reset your password</h1>
          <div className='frm_otr_wrap'>
          <div className='frm_inner_wrap'>
          <form onSubmit={PasswordReset} className='frm_container' method="post">
          <div class="form-group email_grp">
            <input type="text" id="email_Phone" name="emailPhonepsrst" placeholder='Email address' value={formData.emailPhonepsrst} onChange={SmthandleChangerst} ref={emailPhonepsrstRef} />
            <div className="frm_error">{errors.emailPhoneerr}</div>
          </div>
            <button type="submit" className='sbt_btn'>Send me reset link</button>
            </form>
           
        </div>
        <div><a href="/login" className='lgn_rdrct_link'>Go back to login</a></div> 
        </div>

        </div>

        </div>
        </div>
        <div className='tab_head_container_right'>
      <div className='das_tab_container'><img src={PasswordRestGif} className="PasswordRestGif" alt="left image" /></div>
     </div>
        </div>
        </>
       
    );
}
export default PasswordReset;