// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site_blw_wrap.regressiontest{
    margin-top: 35px;
}
/* td.table_td_regression {
    padding-left: 140px;
    color: white;
    font-size: 14px;
} */
.site_regress_chart_info{
    box-shadow: 1px -3px 20px #0b203394;
    width: 100%;
background: #fff;
opacity: 1;
backdrop-filter: blur(11px);
-webkit-backdrop-filter: blur(11px);
margin-top: 0px;
padding-top: 12px !important;
padding-bottom: -11px !important;

}
.MuiTab-textColorPrimary.Mui-selected {
    z-index: 7;
    background: #ff601f !important;
}
.tabbutton{
    width: 130px;
height: 50px;
/* UI Properties */
background: transparent linear-gradient(142deg, #FFFFFF40 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box;
border-radius: 9px;
opacity: 1;
-webkit-backdrop-filter: blur(11px);
        backdrop-filter: blur(11px);
margin-left: 0px;
}
.competitor_test_filter .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    height: 80%;
}
.MuiDataGrid-row .css-17n9xuu-MuiDataGrid-root .MuiDataGrid-cell:first-child {
    position: unset !important;
}
.virtualScrollerContent .css-17n9xuu-MuiDataGrid-root .MuiDataGrid-cell:first-child {
    position: unset;
}
.css-17n9xuu-MuiDataGrid-root .MuiDataGrid-cell:first-child{
    position: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/NavBar/MissedOpportunity/Missedstyle.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;;;;GAIG;AACH;IACI,mCAAmC;IACnC,WAAW;AACf,gBAAgB;AAChB,UAAU;AACV,2BAA2B;AAC3B,mCAAmC;AACnC,eAAe;AACf,4BAA4B;AAC5B,gCAAgC;;AAEhC;AACA;IACI,UAAU;IACV,8BAA8B;AAClC;AACA;IACI,YAAY;AAChB,YAAY;AACZ,kBAAkB;AAClB,yGAAyG;AACzG,kBAAkB;AAClB,UAAU;AACV,mCAA2B;QAA3B,2BAA2B;AAC3B,gBAAgB;AAChB;AACA;IACI,WAAW;AACf;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,eAAe;AACnB;AACA;IACI,0BAA0B;AAC9B","sourcesContent":[".site_blw_wrap.regressiontest{\n    margin-top: 35px;\n}\n/* td.table_td_regression {\n    padding-left: 140px;\n    color: white;\n    font-size: 14px;\n} */\n.site_regress_chart_info{\n    box-shadow: 1px -3px 20px #0b203394;\n    width: 100%;\nbackground: #fff;\nopacity: 1;\nbackdrop-filter: blur(11px);\n-webkit-backdrop-filter: blur(11px);\nmargin-top: 0px;\npadding-top: 12px !important;\npadding-bottom: -11px !important;\n\n}\n.MuiTab-textColorPrimary.Mui-selected {\n    z-index: 7;\n    background: #ff601f !important;\n}\n.tabbutton{\n    width: 130px;\nheight: 50px;\n/* UI Properties */\nbackground: transparent linear-gradient(142deg, #FFFFFF40 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box;\nborder-radius: 9px;\nopacity: 1;\nbackdrop-filter: blur(11px);\nmargin-left: 0px;\n}\n.competitor_test_filter .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {\n    height: 80%;\n}\n.MuiDataGrid-row .css-17n9xuu-MuiDataGrid-root .MuiDataGrid-cell:first-child {\n    position: unset !important;\n}\n.virtualScrollerContent .css-17n9xuu-MuiDataGrid-root .MuiDataGrid-cell:first-child {\n    position: unset;\n}\n.css-17n9xuu-MuiDataGrid-root .MuiDataGrid-cell:first-child{\n    position: unset !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
