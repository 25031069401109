import React from "react";
import { GoogleLogin } from '@react-oauth/google';
import AppleLoginCom from "./AppleLogin";

const GoogleLoginCom=()=>{


    return(
        <div className="over_all_container">

          <div className="google_apple_log_container">


          <GoogleLogin
            onSuccess={credentialResponse => {

                console.log((credentialResponse.credential))
           
            }}
          
            onError={() => {
              console.log('Login Failed');
            }}
          
          />

          <AppleLoginCom/>
          </div>


        </div>
    )
}
export default GoogleLoginCom