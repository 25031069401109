import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SetUserSubscription } from "../../../Store/SubscriptionSlice";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
export default function DetailPlan(props) {
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const dispatchRedux = useDispatch();

  const [MonthlyPlanList, SetMonthlyPlanList] = useState([]);
  const [PlanInfo, SetPlanInfo] = useState([]);
  const [DomainCrawlsData, SetDomainCrawlsData] = useState([]);
  const [OppurtunitesData, SetOppurtunitesData] = useState([]);
  const [loading, Setloading] = useState(false);

  const [PositionTrackingData, SetPositionTrackingData] = useState([]);
  const plandetails=useParams()
  const planid=plandetails.id;
  const GetPlanListing = async () => {
    Setloading(true);
      try {
        const Response = await axios.get(
          `${process.env.REACT_APP_API_URL}accounts/get_subscription_plan/?plan_id=${planid}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        );
        if (Response.data.plans) {
          SetPlanInfo(Response.data.plans[0]);
        }
      } catch (err) {
        console.log(err);
      }
    Setloading(false);
  };

  useEffect(() => {
    GetPlanListing();
  }, []);

  const ButtonWrapper = ({ type }) => {
    const [{ options }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
      dispatch({
        type: "resetOptions",
        value: {
          ...options,
          intent: "subscription",
        },
      });
    }, [type]);

    return (
      <PayPalButtons
        createSubscription={(data, actions) => {
          return actions.subscription
            .create({
              plan_id: planid,
            })
            .then((orderId) => {
         
              // Your code here after create the order
              return orderId;
            });
        }}
        onApprove={async (data) => {
          if (data) {
            try {
              const datavalapi = await axios.post(
                `${process.env.REACT_APP_API_URL}accounts/save_paypal_subscription/`,
                data,
                {
                  headers: {
                    Authorization: `${ApiToken}`,
                  },
                }
              );

              if (datavalapi.data) {
           
                if(datavalapi.data.status)
                {
                  if(datavalapi.data.status=='ACTIVE')
                  {
                    dispatchRedux(SetUserSubscription({eso_user_subscription:false}))
                  }
                  else{
                    dispatchRedux(SetUserSubscription({eso_user_subscription:true}))
                  }
                  
                }
                props.SetShowDetailTab(false);
                props.GetCurrentPlan();
                props.GetPlanListing();
                toast.success(
                  "Your subscription plan has been successfully processed.",
                  {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
                window.scrollTo(0, 0);
              }
            } catch (err) {

              toast.error("Subscription has been failed!", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });


            }
          }
        }}
        onError={(data) => {
          toast.error("Payment has been failed. Please try again.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }}
        onCancel={(data) => {
          toast.error("Payment has been canceled. Please try again.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }}
        style={{
          label: "subscribe",
        }}
      />
    );
  };

  const CloseDetailPage = () => {
    props.SetShowDetailTab(false);
  };

  const RemoveDashes = (value) => {
    return value
      .replace(/_/g, " ")
      .replace(/\b\w/g, (firstChar) => firstChar.toUpperCase());
  };

  return (
    <div className="over_all_container">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="subscription_wrap">
          <div className="plans_list plan_detail_wrap">
            <div class="container">
                <div className="lists">
                  {/* <div className="close_icon" onClick={CloseDetailPage}>
                    <HighlightOffIcon />
                  </div> */}

                  <div className="first_row">
                    <div>
                      <p class="type">{PlanInfo.subscription_name}</p>
                      <p>{PlanInfo.name}</p>
                      <p>{PlanInfo.description}</p>
                    </div>

                      <p class="price">
                        {"$" + PlanInfo.monthly_amount_in_USD + "/" + PlanInfo.type}
                      </p>
                   

                    <div className="plan_details_table_wrap">
                      <div>
                        <h1 className="title">Domains crawls and users</h1>
                        <table>
    <thead>
        <tr></tr>
    </thead>
    <tbody>
        <tr>
            <td>No Of Domains</td>
            <td>{PlanInfo.domains_crawls_and_users?.no_of_domains}</td>
        </tr>
        <tr>
            <td>No Of Campaigns</td>
            <td>{PlanInfo.domains_crawls_and_users?.no_of_campaigns}</td>
        </tr>
        <tr>
            <td>No Of Keywords</td>
            <td>{PlanInfo.domains_crawls_and_users?.no_of_keywords}</td>
        </tr>
        <tr>
            <td>Max No Of Web Pages For Crawl</td>
            <td>{PlanInfo.domains_crawls_and_users?.max_no_of_web_pages_for_crawl}</td>
        </tr>
        <tr>
            <td>Spell Check Urls</td>
            <td>{PlanInfo.domains_crawls_and_users?.spell_check_urls}</td>
        </tr>
        <tr>
            <td>No Of Competitors</td>
            <td>{PlanInfo.domains_crawls_and_users?.no_of_competitors}</td>
        </tr>
    </tbody>
</table>

                      </div>

                      <div>
                        <h1 className="title">Opportunities And Backlinks</h1>
                        {/* <table>
                          <thead>
                            <tr></tr>
                          </thead>
                          <tbody> */}
                            {/* {PlanInfo.opportunities_and_backlinks.map((item) => (
                              <tr>
                                <td>{RemoveDashes(item.key)}</td>
                                {item.value === true ? (
                                  <td className="plan_yes">
                                    <CheckCircleOutlineIcon />
                                  </td>
                                ) : null}
                                {item.value === false ? (
                                  <td className="plan_no">
                                    <HighlightOffIcon />
                                  </td>
                                ) : null}
                                {item.value !== false && item.value !== true ? (
                                  <td>{item.value}</td>
                                ) : null}
                              </tr>
                            ))} */}
                          {/* </tbody> */}
                        {/* </table> */}
                        <table>
                        <tbody>
        <tr>
            <td>Missed Opportunities</td>
               {PlanInfo?.opportunities_and_backlinks?.missed_opportunities==true ?
               <td class="plan_yes">
            <CheckCircleOutlineIcon />
            </td>: <td className="plan_no">
                                    <HighlightOffIcon />
                                  </td>
            }
        </tr>
        <tr>
            <td>Backlinks Vs Competitors</td>
            {PlanInfo?.opportunities_and_backlinks?.backlinks_vs_competitors==true ?
               <td class="plan_yes">
            <CheckCircleOutlineIcon />
            </td>: <td className="plan_no">
                                    <HighlightOffIcon />
                                  </td>
            }
        </tr>
    </tbody>
</table>
                      </div>

                      <div>
                        <h1 className="title">Position Tracking</h1>
                        {/* <table>
                          <thead>
                            <tr></tr>
                          </thead>
                          <tbody>
                            {PositionTrackingData.map((item) => (
                              <tr>
                                <td>{RemoveDashes(item.key)}</td>
                                {item.value === true ? (
                                  <td className="plan_yes">
                                    <CheckCircleOutlineIcon />
                                  </td>
                                ) : null}
                                {item.value === false ? (
                                  <td className="plan_no">
                                    <HighlightOffIcon />
                                  </td>
                                ) : null}
                                {item.value !== false && item.value !== true ? (
                                  <td>{item.value}</td>
                                ) : null}
                              </tr>
                            ))}
                          </tbody>
                        </table> */}
                        <table>
                        <thead>
                            <tr></tr>
                        </thead>
                        <table>
                            <thead>
                                <tr></tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Mobile Rank</td>
                                    {PlanInfo?.position_tracking?.mobile_rank==true ?
               <td class="plan_yes">
            <CheckCircleOutlineIcon />
            </td>: <td className="plan_no">
                                    <HighlightOffIcon />
                                  </td>
            }
                                </tr>
                                <tr>
                                    <td>Zeroth Rank</td>
                                    {PlanInfo?.position_tracking?.zeroth_rank==true ?
               <td class="plan_yes">
            <CheckCircleOutlineIcon />
            </td>: <td className="plan_no">
                                    <HighlightOffIcon />
                                  </td>
            }
                                </tr>
                            </tbody>
                        </table>
                    </table>
                    {/* {PlanInfo?.client_id} */}
                      </div>
                    </div>
                  </div>

                  
                  <div className="paypal_subs_button_wrap">
                    <div className="paypal_subs_button">
                    {PlanInfo?.client_id && (
                      <PayPalScriptProvider
                        options={{
                          clientId: PlanInfo?.client_id,
                          components: "buttons",
                          intent: "subscription",
                          vault: true,
                        }}
                      >
                        <ButtonWrapper type="subscription" />
                      </PayPalScriptProvider>
                          )}

                    </div>
                  </div>
                  

                
                </div>
           
            </div>
          </div>
     
      </div>
    </div>
  );
}
