import React from "react";
import InfoIcon from '@mui/icons-material/Info';

const ErrorService=()=>{
    return (
        <>
        <div className="error_gwt_notice_Outer" style={{marginTop:'35px'}}>
        <div className="error_gwt_notice">
            <div className="row">
            <div className="icon">
        <InfoIcon/>
        </div>
        <div className="msg">
   
        You have not allowed ESO to access your Google Analytic data. In order to provide access, follow the below steps:
        </div>
            </div>
     
        </div>
      
          <div className="error_gwt">
      <main>
  <ul class="infoGraphic"> 
    <li>
      <div class="numberWrap">
        <div class="number fontColor1">1</div>
        <div class="coverWrap">
          <div class="numberCover"></div>
        </div>
      </div>
      <div class="content">
        <div class="icon iconCodepen"></div>
        <h2>Step 1</h2>
        <p className="heading_p">Service Account Email:</p>
        <p> This is our service email: @developer.gserviceaccount.com.
                Kindly add this email by following the below steps.</p>
      </div>
    </li>
    <li>
      <div class="numberWrap">
        <div class="number fontColor2">2</div>
        <div class="coverWrap">
          <div class="numberCover"></div>
        </div>
      </div>
      <div class="content">
        <div class="icon iconRecipient"></div>
        <h2>Step 2</h2>
        <p className="heading_p">Recipient's Google Analytics:</p>
        <p>Log in to your Google Analytics account.</p>
      </div>
    </li>
    <li>
      <div class="numberWrap">
        <div class="number  fontColor3">3</div>
        <div class="coverWrap">
          <div class="numberCover"></div>
        </div>
      </div>
      <div class="content">
        <div class="icon iconAdmin"></div>
        <h2>Step 3</h2>
        <p className="heading_p">Access Admin Settings:</p>
        <p>In the left-hand menu, navigate to "Admin" located at the bottom.</p>
      </div>
    </li>
    <li>
      <div class="numberWrap">
        <div class="number  fontColor4">4</div>
        <div class="coverWrap">
          <div class="numberCover"></div>
        </div>
      </div>
      <div class="content">
        <div class="icon iconManagement"></div>
        <h2>Step 4</h2>
        <p className="heading_p">User Management</p>
        <p>Under the desired level (View), click on "User Management."</p>
      </div>
    </li>
    <li>
      <div class="numberWrap">
        <div class="number  fontColor5">5</div>
        <div class="coverWrap">
          <div class="numberCover"></div>
        </div>
      </div>
      <div class="content">
        <div class="icon iconAddUser"></div>
        <h2>Step 5</h2>
        <p className="heading_p">Add User</p>
        <p> Click on the "+" button to add a new user.</p>
      </div>
    </li>
       <li>
      <div class="numberWrap">
      <div class="number  fontColor6">6</div>
      <div class="coverWrap">
        <div class="numberCover"></div>
      </div>
        </div>
      <div class="content">
        <div class="icon iconAddEmail"></div>
        <h2>Step 6</h2>
        <p className="heading_p">Enter Email</p>
        <p>Kindly enter the provided service account email into the "Email" field.</p>
      </div>
    </li>

    <li>
      <div class="numberWrap">
      <div class="number  fontColor6">7</div>
      <div class="coverWrap">
        <div class="numberCover"></div>
      </div>
        </div>
      <div class="content">
        <div class="icon iconBulb"></div>
        <h2>Step 7</h2>
        <p className="heading_p">Set Permissions</p>
        <p>Choose the "Read & Analyze" role to grant view access.</p>
      </div>
    </li>

    <li>
      <div class="numberWrap">
      <div class="number  fontColor6">7</div>
      <div class="coverWrap">
        <div class="numberCover"></div>
      </div>
        </div>
      <div class="content">
        <div class="icon iconAddButton"></div>
        <h2>Step 8</h2>
        <p className="heading_p">Click Add</p>
        <p> Click on the "Add" button to finalize the process.</p>
      </div>
    </li>

    <li>
      <div class="numberWrap">
      <div class="number  fontColor6">7</div>
      <div class="coverWrap">
        <div class="numberCover"></div>
      </div>
        </div>
      <div class="content">
        <div class="icon iconConfirm"></div>
        <h2>Step 9</h2>
        <p className="heading_p">Confirmation</p>
        <p>
  Confirm with us that the service account email has been added successfully and has the intended level of access.</p>
      </div>
    </li>

    <li>
      <div class="numberWrap">
      <div class="number  fontColor6">7</div>
      <div class="coverWrap">
        <div class="numberCover"></div>
      </div>
        </div>
      <div class="content">
        <div class="icon iconProvider"></div>
        <h2>Step 10</h2>
        <p className="heading_p">Provide Property ID</p>
        <p>
  Kindly provide your property ID into our user management.</p>
      </div>
    </li>
  </ul>

</main>


        </div>
        </div>
        </>
      
    )
}
export default ErrorService