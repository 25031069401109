import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import Box from "@mui/material/Box";
import "./../style.css";
import "./searchkeyvolumestyle.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from "react-dropdown-select";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import AppleIcon from "@mui/icons-material/Apple";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { ChartContainer, BarPlot } from "@mui/x-charts";
import Loader from "../CommonLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import { useNavigate } from "react-router-dom";
import { Commonfun } from './../Commonfun';



export default function Searchkeyvolumelist() {
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [searchopen, setsearchopen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [dateIsOpen, setdateIsOpen] = React.useState(false);
  const [AnalyzeUrl, SetAnalyzeUrl] = useState("");
  const [ErrorMsg, SetErrorMsg] = useState("");
  const [locationval, setlocationval] = useState("");
  const [campaignval, setcampaignval] = useState("");
  const [sectionval, setsectionval] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [location_id, setLocation_id] = useState([]);
  const [selectcamptval, setSelectcamptval] = useState([]);
  const [selectsectionval, setSelectsectionval] = useState([]);
  const [locationvaldata, setlocationvaldata] = useState("");
  const [initialDateval, SetInitialDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  
  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const [searchselectedRows, setsearchselectedRows] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [faqData,setfaqData]=useState("2")
  const [autoheightval,SetAutoheightval]=useState(true)
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
  const SetSectionNameHandle=(passvalue)=>{
    setSelectval(passvalue[0]?.value)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setsearchopen(false)
    setOpen(false);
  };
  const AnalyzeReport = async () => {
    setLoading(true);
   
    if (selectval){
   
      var location_val = selectval;
      // console.log(selectval)
    }
    else var location_val = "";
    if (AnalyzeUrl === "" || location_val === "")
    {
      SetErrorMsg("Please Select the Location and Url !");
      setLoading(false);
    }
      
    else {
     
      SetErrorMsg("");
      setlocationvaldata(location_val)
      calltabledataApi(location_val);
    
    }
  };
  const calltabledataApi = async (location_val = "") => {
  
    setLoading(true);
    SetErrorMsg('')
    setCardData([])
    try{

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/v2/searchvolume_table/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_url=${AnalyzeUrl}&location_id=${location_val}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });

      var dataval=[]

      if((AnalyzeUrl.startsWith("http")) || (AnalyzeUrl.startsWith("https")))
      {
        dataval = datavalue?.data?.result?.tasks[0]?.result[0]?.items;
        if(!datavalue?.data?.result)
        {
          SetErrorMsg("No Data available for this keyword");
        }
        var NewlyPushArray=[];
        if(dataval)
        {
         dataval.forEach(element => {
          var NewObject=[];
          NewObject['keyword']=element.keyword_data.keyword
          NewObject['search_volume']=element.keyword_data.keyword_info.search_volume
          NewObject['monthly_searches']=element.keyword_data.keyword_info.monthly_searches
          NewObject['competition']=element.keyword_data.keyword_info.competition
          NewObject={ ...NewObject }
          NewlyPushArray.push(NewObject)
         });
        }
        dataval=NewlyPushArray      
        console.log(dataval)  
      }
      else{
         dataval = datavalue?.data?.result?.tasks[0]?.result;
         console.log(dataval)
      }
      if (dataval && dataval.length>0) {
        const newData = dataval.map((obj, index) => {
          return { ...obj, id: index + 1 };
        });
        SetAutoheightval(false)
        setCardData(newData);
      }
      else{
        setCardData("");
        SetAutoheightval(true)
        SetErrorMsg("No data found for this keyword");
      }
    }catch(err)
    {
      console.log(err)
    }
    setLoading(false);
  };
  const [validation, setValidation] = useState({
    dateerror: "",
  });

  useEffect(() => {
  
    callUserdataApi();
    faqdatafun();
  }, []);
  const columns = [
    {
      field: "keyword",
      headerName: "Keyword",
      description:'List of keywords relevant to the target domain url or Keyword ',
      width: 200,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "left",
      sortable: true,
    },
    {
      field: "search_volume",
      headerName: "Search Volume",
      description:"A keyword's total search volume in search results.",
      width: 200,
      align: "center",
      headerAlign: "center",
      sortable: true,
      renderCell: (cardData) => {

        var LoadData='';

        if(cardData.row.search_volume===null || cardData.row.search_volume==0 )
        {
          LoadData='N/A'
        }
        else{
          LoadData=cardData.row.search_volume
        }
        return(LoadData)

  

      }
    },
    {
      field: "monthly_searches",
      headerName: "Monthly Searches",
      description:'The last 12 months search volume is displayed visually as a simple small bar graph for the keyword.',
      width: 200,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cardData) => {
        var sr = cardData.row.monthly_searches;
     
        if (sr != null && Array.isArray(sr) && cardData.row.search_volume!=0) {
          const numAscending = sr.sort((a, b) => a.month - b.month);
          var monthval = numAscending.map((fruit) => fruit.month);
          var chartdataval = numAscending.map((fruit) => fruit.search_volume);
          return (
            <ChartContainer
              width={200}
              height={150}
              series={[{ data: chartdataval, label: "uv", type: "bar" }]}
              xAxis={[{ scaleType: "band", data: monthval }]}
            >
              <BarPlot />
            </ChartContainer>
          );
        }
        else{
              return ('N/A')
        }
      },
    },
    {
      field: "competition",
      headerName: "Keyword Difficulty",
      description:'The value-based display of keyword difficulty for the respective keyword',
      width: 200,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {

        var LoadData='';

        

        if(cardData.row.competition===null)
        {
          LoadData='N/A'
        }
        else{
          let formattedNumber = cardData.row.competition.toFixed(2);
          let result = formattedNumber* 100;
          LoadData=result
        }
        return(LoadData)

  

      }
    },

    {
      field: "view",
      headerName: "View",
      width: 200,
      headerAlign: "center",
      align: "center",
      sortable: false,
      flex: 1,
      renderCell: () => {
        return (
          <button
                className="run_rpt_btn rn_rpt_btn"
                onClick={campaignreportNow}
              >
                Add To Campaign
              </button>
        ) 
      },
    },
  ];
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);

    try{

      const locationdatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/searchvolumelocations/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      const dataval = locationdatavalue?.data?.Locations;
      var Sections = [];
      locationdatavalue?.data?.Locations.forEach((element) => {
        Sections.push({ label: element.location_name, value: element.id });
      });
      console.log(Sections)
      setlocationval(Sections);

    }catch(err)
    {
      console.log(err)
    }

    try{

      const campaigndatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_campaigns/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      const campaigndataval = campaigndatavalue?.data;
      setcampaignval(campaigndataval);

    }catch(err)
    {
      console.log(err)
    }

    try{

      const sectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      const sectiondataval = sectiondatavalue?.data?.domain_sections;
      setsectionval(sectiondataval);

    }catch(err)
    {
      console.log(err)
    }

  

    setLoading(false);
  };
  const campaignreportNow = async () => {    
    setsearchselectedRows(selectedRows)
    setfaqData(2)
    setToggle(false) 
    if (selectedRows.length > 0) setIsOpen(true);
    else setsearchopen(true);
  };

  const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};
    if (selectcamptval.length <= 0 ) {
      errors.selectval = "Campaign Name is required.";
      errs = 2;
      setLoading(false);
    } 
     if (selectsectionval.length <= 0)
     {
      errors.dateerror = "Section field is required.";
      errs = 2;
      setLoading(false);
     }
    // else {
    //   errors.dateerror = "";
    //   errs = 1;
    //   setValidation(errors);
    // }
    if (errs == 2) {
       setValidation(errors);
       return false;
    }
    else{
      errors.dateerror = "";
        errs = 1;
        setValidation(errors);
         var keywordselectdata = searchselectedRows.map(
        (dataselectval) => dataselectval.keyword
    );
        savetargetkeydata(keywordselectdata);
        // AnalyzeReport();
        setLoading(false);
    }
  };
  const savetargetkeydata = async (keywordselectdata) => {
    setIsOpen(false);
    try{
    const datavalapi = await axios.post(
      `${process.env.REACT_APP_API_URL}keywords/keyword_research_save/`,
      {
        user_domain_id: esodata.domaindata.value.header_selected,
        domain_section_id: selectsectionval[0].id,
        category_id: selectcamptval[0].id,
        keywords: keywordselectdata,
      },
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });
    setSelectsectionval([])
    setSelectcamptval([])
    setSelectedRows([])
    if(datavalapi?.status==200){
      setapiOpen(true);
      setapierrorMsg(datavalapi?.data?.status)
    }
   
    }
    catch(err)
      {

        toast.error(err.response.data.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        console.log(err)
      }
   
    //  calltabledataApi( selectval[0]?.id)
    // closeModal();
    // handleClickOpen();
  };
  function closeModal() {
    setIsOpen(false);
    // calltabledataApi( selectval[0]?.id)
  }

  const handleChange=(e)=>{
    if(e.key==='Enter')
      AnalyzeReport()
  }
  const pdfdown = async () =>{ 
    setLoading(true);
    try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}keywords/v2/searchvolume_table/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_url=${AnalyzeUrl}&location_id=${locationvaldata}&formats=pdf`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
              
                var filedata=await Commonfun("Searchvolumelist","pdf", ApiToken,esodata.domaindata.value.header_selected);
                link.download = filedata;
                 link.click();

                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              // toast.error("No Data", {
              //   position: "top-center",
              //   autoClose: 5000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              //   progress: undefined,
              //   theme: "light",
              //   });
              //   console.error('Failed to fetch PDF:', response.status, response.statusText);
              if(response?.status==599 || response?.status==500 || response?.status==400  || response?.status==404 ){
                response.json().then(data => {
                  setapiOpen(true);
                  setapierrorMsg(data?.error)
                  console.log('Resolved object:', data.error);
                })
              }
              else if(response?.code =="ERR_NETWORK"){
                navigate('/errorpages')    
              }
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);

  }
  const csvdown = async () =>{ 
    setLoading(true);
    try{
   
      const response = await fetch(`${process.env.REACT_APP_API_URL}keywords/v2/searchvolume_table/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_url=${AnalyzeUrl}&location_id=${locationvaldata}&formats=csv`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                
                var filedata=await Commonfun("Searchvolumelist","csv", ApiToken,esodata.domaindata.value.header_selected);
                link.download = filedata;
                 link.click();
           

                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              // toast.error("No Data", {
              //   position: "top-center",
              //   autoClose: 5000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              //   progress: undefined,
              //   theme: "light",
              //   });
              //   console.error('Failed to fetch PDF:', response.status, response.statusText);
              if(response?.status==599 || response?.status==500 || response?.status==400  || response?.status==404 ){
                response.json().then(data => {
                  setapiOpen(true);
                  setapierrorMsg(data?.error)
                  console.log('Resolved object:', data.error);
                })
              }
              else if(response?.code =="ERR_NETWORK"){
                navigate('/errorpages')    
              }
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);
  }
  const faqdatafun = async(menubutval="") => {
    var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=8`
  
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
       
        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) {}
  }
  const handleToggle = () => {
    if(faqData!=1){
      setfaqData(1)
    }
    else{
      setfaqData(2)
    }
        setToggle((pre) => !pre);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') || 
    (event.target.classList[0] === 'nav_key_txt') || 
    (event.target.classList[0] === 'cnt_hdr_top_wrap') ||(event.target.classList[0] === 'react-dropdown-select') 
    ||(event.target.classList[0] === 'react-dropdown-select-content') 
    ||(event.target.classList[0] === 'react-dropdown-select-item') 
    || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap')   || (event.target.classList[0] === 'App')  
    || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
     || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row') 
     || (event.target.classList[0] === 'MuiDataGrid-cell')  || (event.target.classList[0] === 'MuiDataGrid-cellContent')
     || (event.target.classList[0] === 'header_inner_btm_wrap')||(event.target.classList[0] === 'over_all_container') 
     ||(event.target.classList[0] === 'input_domain_field') ||(event.target.classList[0] === 'react-dropdown-select-input') ) {
      setfaqData(2)
      setToggle(false)   
    }
});
  return (
    <div className="over_all_container search_prnt">
        <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Search Key Volume List </title>
            </Helmet>
      {
      loading===true ?   <Loader/> : null
    }

      <div
        style={{
          width: "calc(100% - 40px)",
          marginRight: "40px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="cnt_audit_outer_wrap">
      <div className=" search_key_wrap searchvolume_key_wrap">
        <div className="cnt_hdr_top_wrap">
          <div className="cnt_hdr_top_lft_wrap">
            <h2>Search Volume</h2>
            {/* <p>as of {jobDate}</p> */}
          </div>
          <div className="cnt_hdr_top_rgt_wrap">
          <div className={`App ${faqData=="1"?'faq_move_class':""}`} >
            <button onClick={handleToggle} className="faq_class"  title="Frequently Asked Question">
            <QuestionMarkIcon />
              
            </button>
           {toggle && <Keywordbar close={() => setToggle(false)} initialDateval={initialDateval} />} 
          </div>
          <img src={pdficon} alt="pdf icon" onClick={pdfdown}/>
          <img src={csvicon} alt="csv icon" onClick={csvdown} />
          </div>
        </div>

        <div className="plagarism_analyzer_row">
          <div className="input_col search_location_with_tool">

          <Tooltip
          className="svg_search"
                    title="Select the location for which the keyword research has to be accomplished."
                    arrow
                  >
                    <HelpIcon style={{ width: "0.5em" }} />
                  </Tooltip>
                  
                  <Select
                  className="drop-organicsearch"
                  name="name"
                  onChange={SetSectionNameHandle}
                  options={locationval}
                  clearOnSelect	="true"
                  clearOnBlur="true"
                  searchable="true"
                  clearable="true"
                   placeholder="Select Location"
                   />                
          </div>
          <div className="input_col domain_with_tool">

          <Tooltip
          className="svg_search"
                    title="The domain URL or keyword for which the keyword research is to be done."
                    arrow
                  >
                    <HelpIcon style={{ width: "0.5em" }} />
                  </Tooltip>
            <input
              type="text"
              className="input_domain_field"
              placeholder="Domain URL or Keyword"
              onChange={(e) => {
                SetAnalyzeUrl(e.target.value);
              }}
              onKeyPress={handleChange}
            />


          </div>
          <div className="button_col">
            <button className="run_rpt_btn rn_rpt_btn" onClick={AnalyzeReport}>
              Analyze
            </button>
          </div>
        </div>
     
        <div className="error_plagarism_report" style={{textAlign:'center',color:'#ff0000'}}>{ErrorMsg}</div>
        {cardData.length > 0 && (
          <div className="search_volumn_data">
            <div className="cnt_hdr_blw_wrap">
              <button
                className="run_rpt_btn rn_rpt_btn"
                onClick={campaignreportNow}
              >
                Add To All Campaigns
              </button>
            </div>
            <div className="data_table search_volume_table" style={{ width: "100%" }}>
              <div style={{ height: 550, width: "100%" }}>
                <DataGrid
                   slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar
                  }}
                  slotProps={{
                    toolbar: {
                  
                      showQuickFilter: true,
                    
                    },
                  }}
                  rows={rows}
                  columns={columns}
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                      {
                        py: "22px",
                      },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                    ".MuiDataGrid-virtualScroller": {
                      height: "auto !important",
                      overflowY: "auto",
                    },
                    "& .MuiDataGrid-cell:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      zIndex: 999,
                    },
                    "& .MuiDataGrid-columnHeader:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      border: "none",
                      zIndex: 999,
                    },
                  }}
                  gridRowId={(row) => row.id}
                  getRowHeight={() => "auto"}
                  autoHeight={autoheightval?true:false}      
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10, 30, 50, 75, 100]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  loading={loading}
                  checkboxSelection
                  onRowSelectionModelChange={(ids) => {
                    const selectedRowsData = ids.map((id) =>
                      rows.find((row) => row.id === id)
                    );
                    setSelectedRows(selectedRowsData);
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <Dialog
          open={modalIsOpen}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="cnt_hdr_popup_blw_wrap">
                <button className="run_rpt_btn rn_rpt_btn">
                  Add To Campaign
                </button>
              </div>
              <div className="" style={{ marginBottom: "10px" }}>
                <div>
                  <span>Campaign Name </span>
                  <Tooltip
                     className="moda_pop_search_volume_svg"
                    title="Select the campaign name to which the selected keyword(s) should be added."
                    arrow
                  >
                    <HelpIcon style={{ width: "0.5em" }} />
                  </Tooltip>
                  <Select
                    options={campaignval}
                    labelField="name"
                    valueField="id"
                    onChange={(values) => setSelectcamptval(values)}
                  />
                   <span className="error_msg" style={{"color":"White"}}>{validation.selectval}</span>
                </div>
                <div>
                  <span>Sections </span>
                  <Tooltip
                  className="moda_pop_search_volume_svg"
                    title="Select the section name(Prefered Url) to which the selected keyword(s) should be added to the campaign name"
                    arrow
                  >
                    <HelpIcon style={{ width: "0.5em" }} />
                  </Tooltip>
                  <Select
                    options={sectionval}
                    labelField="name"
                    valueField="id"
                    onChange={(values) => setSelectsectionval(values)}
                  />
                </div>
                <span className="error_msg" style={{"color":"White"}}>{validation.dateerror}</span>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="submitbutton">
              <button className="run_rpt_btn rn_rpt_btn" onClick={reportNow}>
                Submit
              </button>
              <button className="run_rpt_btn rn_rpt_btn" onClick={closeModal}>
                Close
              </button>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" 
        className="formsubmit_popup"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          A new keyword has been added to the Targeted Keywords
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="submitbutton">
          <Button className="run_rpt_btn rn_rpt_btn" onClick={handleClose}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={searchopen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" 
        className="formsubmit_popup"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
           Please Select Data!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="submitbutton">
          <Button className="run_rpt_btn rn_rpt_btn" onClick={handleClose}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
      </div>
      </div>
    </div>
  );
}
