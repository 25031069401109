import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import PdfFile from "./PdfFile";
import WordFile from "./WordFile";
import "react-datepicker/dist/react-datepicker.css";

export default function ContentauditDetail() {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jobDate, setJobDate] = useState("");
  const [toggle, setToggle] = useState(false);
  const [faqData, setFaqData] = useState("2");
  const [initialFaqDataVal, setInitialFaqDataVal] = useState([]);
  const [apiOpen, setApiOpen] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState();
  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;

  const { jobid } = useParams();
  let cont_audit_id = jobid;

  useEffect(() => {
    callUserdataApi();
    faqdatafun();
  }, [cont_audit_id, esodata.domaindata.value.header_selected, ApiToken]);

  const callUserdataApi = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/file_accessibility_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${cont_audit_id}`, {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      setCardData(response.data);
    } catch (error) {
      if (error?.response?.status === 599 || error?.response?.status === 400 || error?.response?.status === 500) {
        setApiOpen(true);
        setApiErrorMsg(error?.response?.data?.error);
      } else if (error?.code === "ERR_NETWORK" || error?.response?.status === 404) {
        navigate('/errorpages');
      }
    } finally {
      setLoading(false);
    }
  }, [cont_audit_id, esodata.domaindata.value.header_selected, ApiToken, navigate]);

  const faqdatafun = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=11`, {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      setInitialFaqDataVal(response.data.faqs);
    } catch (error) {
      if (error?.response?.status === 599 || error?.response?.status === 400 || error?.response?.status === 500) {
        setApiOpen(true);
        setApiErrorMsg(error?.response?.data?.error);
      } else if (error?.code === "ERR_NETWORK" || error?.response?.status === 404) {
        navigate('/errorpages');
      }
    }
  }, [ApiToken, navigate]);

  const handleToggle = useCallback(() => {
    setFaqData(prevFaqData => prevFaqData !== "1" ? "1" : "2");
    setToggle(prevToggle => !prevToggle);
  }, []);

  useEffect(() => {
    const handleClick = (event) => {
      const classList = event.target.classList;
      const toggleFaqData = () => {
        setFaqData("2");
        setToggle(false);
      };
      const allowedClasses = [
        'cnt_hdr_blw_wrap', 'header_wrap', 'nav_key_txt', 'cnt_hdr_top_wrap', 'react-dropdown-select',
        'react-dropdown-select-content', 'react-dropdown-select-item', 'site_cnt_hdr_blw_wrap', 'App',
        'cnt_audit_outer_wrap', 'MuiDataGrid-columnHeaderTitleContainer', 'MuiDataGrid-toolbarContainer',
        'MuiDataGrid-row', 'MuiDataGrid-cell', 'MuiDataGrid-cellContent', 'header_inner_btm_wrap', 'over_all_container',
        'input_domain_field', 'react-dropdown-select-input', 'cnt_hdr_content_table', 'hor_line', 'box_wrap', 'table', 'undefined'
      ];
      if (allowedClasses.includes(classList[0])) {
        toggleFaqData();
      }
    };

    const rootElement = document.getElementById('root');
    rootElement.addEventListener('click', handleClick);

    return () => {
      rootElement.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div className="over_all_container">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>File Accessibility Detail</title>
      </Helmet>
      <div className="cnt_audit_outer_wrap ContentAuditWrap">
        <div className="cnt_hdr_top_wrap">
          <div className="box_wrap schema_anaysis_outer_wrap">
            <div className="cnt_hdr_top_lft_wrap">
              <h2>File Accessibility Detail Report</h2>
              <p><b>{cardData.url}</b></p>
            </div>
          </div>
        </div>
      </div>
      {cardData?.detailed_data?.file_type === "pdf" ? (
        <PdfFile cardData={cardData} setCardData={setCardData} />
      ) : (
        <WordFile cardData={cardData} setCardData={setCardData} />
      )}
    </div>
  );
}
