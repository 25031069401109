import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import axios from "axios";
import Loader from "../CommonLoader";

function EditForm() {
  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;

  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [permissions, setPermissions] = useState({
    view_report: 0,
    run_report: 0,
    add_user: 0,
    edit_user: 0,
    site_audit_settings: 0,
    add_domain: 0,
    edit_domain: 0,
    delete_domain: 0,
    add_domain_section: 0,
    edit_domain_section: 0,
    delete_domain_section: 0,
    performance_settings: 0,
    add_competitor: 0,
    edit_competitor: 0,
    delete_competitor: 0,
    spell_checker_settings_add_ignore_word: 0,
    spell_checker_settings_delete_ignore_word: 0,
    regional_level_settings: 0,
    add_keyword: 0,
    edit_keyword: 0,
    delete_keyword: 0,
    add_brand_terms: 0,
    edit_brand_terms: 0,
    delete_brand_terms: 0,
    add_campaign: 0,
    edit_campaign: 0,
    delete_campaign: 0,
  });

  const [isChecked, setIsChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [apiopen, setApiOpen] = useState(false);
  const [alertapiopen, setAlertapiOpen] = useState(false);

  const [apierrorMsg, setApiErrorMsg] = useState("");
  const [onPageError, setOnPageError] = useState("");
  const [onEmailError, setOnEmailError] = useState("");

  const [permissionsError, setPermissionsError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const allChecked = Object.values(permissions).every((value) => value === 1);
    setIsChecked(allChecked);
  }, [permissions]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleApiClose = () => {
    setApiOpen(false);
  };
  const alerthandleApiClose = () =>{
    setAlertapiOpen(false)
    navigate("/user-list");
  }

  const finalReportCancel = () => {
    navigate("/user-list");
  };

  const handleChange = (e) => {
    const { id, checked } = e.target;
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [id]: checked ? 1 : 0,
    }));
  };

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    setPermissions((prevPermissions) => {
      const updatedPermissions = {};
      for (const key in prevPermissions) {
        updatedPermissions[key] = checked ? 1 : 0;
      }
      return updatedPermissions;
    });
  };

  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = () => {
    let isValid = true;
    if (email === "") {
      setOnEmailError("Email is required");
      isValid = false;
    } else if (!validateEmail(email)) {
      setOnEmailError("Invalid email format");
      isValid = false;
    } else {
      setOnEmailError("");
    }

    if (!role) {
      setOnPageError("Role is required");
      isValid = false;
    } else {
      setOnPageError("");
    }

    if (role === "Member") {
      const selectedCount = Object.values(permissions).filter((value) => value === 1).length;
      if (selectedCount === 0) {
        setPermissionsError("Select at least one permission");
        isValid = false;
      } else {
        setPermissionsError("");
      }
    } else {
      setPermissionsError("");
    }

    return isValid;
  };

  const onSubmitReport = async () => {
    if (!validateForm()) return;
    try {
      setLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}settings/user_management_add_user/`,
        {
          email,
          role,
          permission: permissions,
        },
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false);
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setApiOpen(true);
          setApiErrorMsg(error?.response?.data?.error);
        } else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
      });

      if (response?.status == 200) {
        setLoading(false);
        setAlertapiOpen(true);
        setApiErrorMsg(response.data.message);
      }

    } catch (error) {
      console.error("Error adding user:", error);
      setApiErrorMsg(error?.response?.data?.error);
      setAlertapiOpen(true);
    }
  };

  return (
   
    <div className="over_outer_wrap add-form" style={{ background: "#e3e4e7", minHeight: "800px" }}>
      <div className="overall-container" style={{ top: "30px" }}>
        {loading === true ? <Loader /> : null}
        <div className="user-role-container">
          <div className="user-role-inner form-container">
            <h2>Add Role</h2>
            <form className="form-content">
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="text"
                  id="email"
                  className="form-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span className="error-message">{onEmailError}</span>
              </div>
              <div className="form-group">
                <label htmlFor="role">Role:</label>
                <select
                  id="role"
                  className="form-select"
                  value={role}
                  onChange={(e) => {
                    setRole(e.target.value);
                    // Reset permissions when role changes
                    setPermissions({
                      view_report: 0,
                      run_report: 0,
                      add_user: 0,
                      edit_user: 0,
                      site_audit_settings: 0,
                      add_domain: 0,
                      edit_domain: 0,
                      delete_domain: 0,
                      add_domain_section: 0,
                      edit_domain_section: 0,
                      delete_domain_section: 0,
                      performance_settings: 0,
                      add_competitor: 0,
                      edit_competitor: 0,
                      delete_competitor: 0,
                      spell_checker_settings_add_ignore_word: 0,
                      spell_checker_settings_delete_ignore_word: 0,
                      regional_level_settings: 0,
                      add_keyword: 0,
                      edit_keyword: 0,
                      delete_keyword: 0,
                      add_brand_terms: 0,
                      edit_brand_terms: 0,
                      delete_brand_terms: 0,
                      add_campaign: 0,
                      edit_campaign: 0,
                      delete_campaign: 0,
                    });
                  }}
                >
                  <option value="" disabled>
                    Select Role
                  </option>
                  <option value="Admin">Admin</option>
                  <option value="Member">Member</option>
                </select>
                <span className="error-message">{onPageError}</span>
              </div>
              {role === "Member" && (
                <div className="form-group">
                  <label htmlFor="permissions">Permission:</label>
                  <div className="permissions-container">
                    <label className="select-all">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleSelectAll}
                      />
                      Select All
                    </label>
                    <div className="permissions-list">
  {Object.entries(permissions).reduce((rows, [key, value], index, array) => {
    if (index % 2 === 0) {
      rows.push(array.slice(index, index + 2));
    }
    return rows;
  }, []).map((pair, rowIndex) => (
    <div key={rowIndex} className="permissions-row">
      {pair.map(([key, value]) => (
        <label key={key} className="permission-item">
          <input
            type="checkbox"
            id={key}
            checked={value === 1}
            onChange={handleChange}
          />
          {key.replace(/_/g, " ")}
        </label>
      ))}
    </div>
  ))}
</div>
                    <span className="error-message">{permissionsError}</span>
                  </div>
                </div>
              )} 
              <div className="form-actions">
                <button type="button" className="btn" onClick={finalReportCancel}>
                  Cancel
                </button>
                <button type="button" className="btn" onClick={onSubmitReport}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Error Message"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {onPageError}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={apiopen}
          onClose={handleApiClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Alert!!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className="alert-error-message">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleApiClose}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={alertapiopen}
          onClose={alerthandleApiClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Alert!!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className="alert-error-message">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={alerthandleApiClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default EditForm;
