import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import isURL from "validator/lib/isURL";
import { useDispatch } from "react-redux";
import { SetDomainHeader } from "../../../Store/DomainData";
import Select from "react-dropdown-select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";


function KeywordAddForm() {
  const [weblinkError, SetweblinkError] = useState("");

  const [onPageError, SetonPageError] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [CampaignList, SetCampaignList] = useState([]);
  const [SelectCampaignList, SetSelectCampaignList] = useState([]);
  const [KeywordName, SetKeywordName] = useState("");
  const [SearchEngine, SetSearchEngine] = useState("");
  const [Engineoptions, SetEngineoptions] = useState([]);
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([]);
  const [Country, SetCountry] = useState("");
  const [State, SetState] = useState("");
  const [City, SetCity] = useState("");
  const [campaignerr, setCampaignerr] = useState("");
  const [engineerr, setEngineerr] = useState("");
  const [countryerr, setCountryerr] = useState("");
  const [stateerr, setStateerr] = useState("");
  const [cityerr, setCityerr] = useState("");
  const [cardmsg, setCardmsg] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [cardData, setCardData] = useState([]);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const navigate = useNavigate();
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  const handleClose = () => {
    setOpen(false);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const handlestateoptions = async (e) => {
    var Country = e.target.value;
    SetCountry(Country);

    const statedatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/get_state_by_country/?country_id=${Country}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });

    if (statedatavalue?.data) {
      if (statedatavalue?.data?.states) {
        var Sections = [];
        statedatavalue.data.states.forEach((element) => {
          Sections.push({ label: element.state_name, value: element.id });
        });
        SetStateoptions(Sections);
        SetState("");
      }
    }
  };
  const handleCityoptions = async (e) => {
    var State = e.target.value;
    SetState(State);

    const citydatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/get_cities_by_state/?state_id=${State}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });

    if (citydatavalue?.data) {
      if (citydatavalue?.data?.cities) {
        var Sections = [];
        citydatavalue.data.cities.forEach((element) => {
          Sections.push({ label: element.city_name, value: element.id });
        });
        SetCityOptions(Sections);
        SetCity("");
      }
    }
  };
  const LoadAddFormFilterOptions = async () => {
    SetSectionOptions([]);
    SetCountryoptions([]);
    SetEngineoptions([]);

    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });

      if (doaminsectiondatavalue?.data) {
        if (doaminsectiondatavalue?.data?.domain_sections) {
          SetDoaminSectionUrl(doaminsectiondatavalue.data.domain_sections);
          var Sections = [];
          doaminsectiondatavalue.data.domain_sections.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetSectionOptions(Sections);
        }
      }
    } catch (err) {}

    try {
      const countrydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_countries/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });

      if (countrydatavalue?.data) {
        if (countrydatavalue?.data) {
          var Sections = [];
          countrydatavalue.data.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetCountryoptions(Sections);
        }
      }
    } catch (err) {}

    try {
      const countrydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_countries/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });

      if (countrydatavalue?.data) {
        if (countrydatavalue?.data) {
          var Sections = [];
          countrydatavalue.data.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetCountryoptions(Sections);
        }
      }
    } catch (err) {}

    try {
      const enginedatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/search_engine_locale_list/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });

      if (enginedatavalue?.data) {
        if (enginedatavalue?.data) {
          var Sections = [];
          enginedatavalue.data.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetEngineoptions(Sections);
        }
      }
    } catch (err) {}
  };
  const GetDefaultSettings = async () => {
    setLoading(true);
    setCardData([]);
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/keywordsettings_view/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        // `${process.env.REACT_APP_API_URL}settings/keywordsettings_view/?user_domain_id=1`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      setLoading(false);
      if (datavalue?.data) {
        setCardData(datavalue.data.keyword_settings);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const finalreportCancel = async () => {
    window.location.href = `${process.env.REACT_APP_DOMAIN}keywords`;
  };
  const finalreportNow = async () => {
    var dataerr = 1;
    setCampaignerr("");
    SetweblinkError("");
    setEngineerr("");
    setCountryerr("");
    setStateerr("");
    setCityerr("");

    if (SelectCampaignList == "") {
      setCampaignerr("Please Select Campaign");
      dataerr = 2;
    }

    if (KeywordName == "") {
      SetweblinkError("Please Enter Keyword");
      dataerr = 2;
    }
    if (SearchEngine == "") {
      setEngineerr("Please Select Search Engine");
      dataerr = 2;
    }
    if (Country == "") {
      setCountryerr("Please Select Country");
      dataerr = 2;
    }
    if(cardData=="State" || cardData=="City"){
      if (State == "") {
        setStateerr("Please Select State");
        dataerr = 2;
      }
      if( cardData=="City"){
        if (City == "") {
          setCityerr("Please Select City");
          dataerr = 2;
        }
      }
      
    }
    if (dataerr == 1) {
      try {
        const Response = await axios.post(
          `${process.env.REACT_APP_API_URL}keywords/add_keyword/`,
          {
            user_domain_id: esodata.domaindata.value.header_selected,
            campaign_id: SelectCampaignList,
            keywords: [
              {
                keyword: KeywordName,
                search_engine_id: SearchEngine,
                country_id: Country,
                state_id: State,
                city_id: City,
              },
            ],
          },
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
            navigate('/errorpages')    
          }
          return false;
          // console.log("kannan",error)
        });
        if (
          Response?.data?.message ==
          "keyword and search location added for this campaign"
        ) {
          navigate("/keywords");
        }
      } catch (Response) {
        setCampaignerr("");
        SetweblinkError("");
        setEngineerr("");
        setCountryerr("");
        setStateerr("");
        setCityerr("");
        setCardmsg(Response?.response?.data?.error);
      }
      // setOpen(true);
    } else {
      return false;
    }
  };
  useEffect(() => {
    GetDefaultSettings();
    LoadAddFormFilterOptions();
    GetCampaignData();
  }, []);
  const GetCampaignData = async () => {
    SetCampaignList([]);

    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/campaign_table/?user_domain_id=${esodata.domaindata.value.header_selected}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });

    if (datavalue?.data) {
      var Sections = [];
      datavalue.data.forEach((element) => {
        Sections.push({
          label: element.campaign_name,
          value: element.campaign_id,
        });
      });
      SetCampaignList(Sections);
    }
  };
  return (
    <div className="over_all_container">
      <div className="userdatas">
        {/* <h1>Domain Data</h1> */}
        <div className="userdatas_inner keywordform_outer">
          <p>Add Keywords</p>
          <span className="error-value">{onPageError}</span>

          <form className="frm_container">
            <div class="form-group user_country websitename">
              <div>
                <label className="webtype" for="city">
                  Campaign Name:
                </label>
                <select
                  className="form-select"
                  onChange={(e) => {
                    SetSelectCampaignList(e.target.value);
                  }}
                >
                  <option value={""}>{"Select Campaign"}</option>
                  {CampaignList.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </select>
              </div>
              <div>
                {" "}
                <span className="error-value">{campaignerr}</span>
              </div>
            </div>
            <div className="form-group user_country websitename">
              <div>
                <label>Keyword Name:</label>
                <input
                  type="text"
                  className="keyword"
                  onChange={(e) => {
                    SetKeywordName(e.target.value);
                  }}
                />
              </div>
              <div>
                <span className="error-value">{weblinkError}</span>
              </div>
            </div>
            <div class="form-group user_country websitename">
              <div>
                <label className="webtype" for="city">
                  Search Engine:
                </label>
                <select
                  className="form-select"
                  onChange={(e) => {
                    SetSearchEngine(e.target.value);
                  }}
                >
                  <option value={""}>{"Select Engine"}</option>
                  {Engineoptions.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </select>
              </div>
              <div>
                {" "}
                <span className="error-value">{engineerr}</span>
              </div>
            </div>
            <div class="form-group user_country websitename">
              <div>
                <label className="webtype" for="city">
                  Country:
                </label>
                <select
                  className="form-select"
                  id="yourElementId"
                  name="country"
                  onChange={(e) => {
                    handlestateoptions(e);
                  }}
                >
                  <option value={""}>{"Select Country"}</option>
                  {Countryoptions.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </select>
              </div>
              <div>
                {" "}
                <span className="error-value">{countryerr}</span>
              </div>
            </div>
            
            { (cardData=="State" || cardData=="City")   &&
            <div class="form-group user_country websitename">
              <div>
                <label className="webtype" for="city">
                  State:
                </label>
                <select
                  className="form-select"
                  name="state"
                  onChange={(e) => {
                    handleCityoptions(e);
                  }}
                >
                  <option value={""}>{"Select State"}</option>
                  {Stateoptions.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </select>
              </div>
              <div>
                {" "}
                <span className="error-value">{stateerr}</span>
              </div>
            </div>
            }
               { cardData==="City"   &&
            <div class="form-group user_country websitename">
              <div>
                <label className="webtype" for="city">
                  City:
                </label>
                <select
                  className="form-select"
                  name="city"
                  onChange={(e) => {
                    SetCity(e.target.value);
                  }}
                >
                  <option value={""}>{"Select City"}</option>
                  {CityOptions.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </select>
              </div>
              <div>
                {" "}
                <span className="error-value">{cityerr}</span>
              </div>
            </div>
              }
            <div className="form_next">
              <button
                className="nextbutton"
                type="button"
                onClick={finalreportCancel}
              >
                Cancel
              </button>
              <button
                className="nextbutton"
                type="button"
                onClick={finalreportNow}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Error Message "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {cardmsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}

export default KeywordAddForm;
