import React, { useState } from "react";
import axios from "axios";
import "./Logindetail.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import isURL from 'validator/lib/isURL';
import { SetDomainHeader } from "../../../Store/DomainData";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";

function Domaindata() {
  const [formState, setFormState] = useState({
    webauthenticate: "No",
    webtype: "JS",
    showResults: false,
    websiteName: "",
    webLink: "",
    onUsername: "",
    onPassword: "",
    errors: {
      webnameError: "",
      weblinkError: "",
      onUserError: "",
      onPasswordError: "",
      onPageError: "",
    },
  });

  const [loading, setLoading] = useState(false);
  const [apiOpen, setApiOpen] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState("");
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ApiToken = useSelector((state) => state.apiauthkey.value);

  const handleClose = () => setApiOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
      ...(name === "webauthenticate" && {
        showResults: value === "Yes",
        onUsername: value === "No" ? "" : prevState.onUsername,
        onPassword: value === "No" ? "" : prevState.onPassword,
      }),
      errors: { ...prevState.errors, [`${name}Error`]: "" },
    }));
  };

  const validateForm = () => {
    const { websiteName, webLink, webauthenticate, onUsername, onPassword } = formState;
    let errors = {};
    let isValid = true;

    if (!websiteName) {
      errors.webnameError = "Enter Website Name";
      isValid = false;
    }

    if (!webLink) {
      errors.weblinkError = "Enter Link Address";
      isValid = false;
    } else if (!isURL(webLink)) {
      errors.weblinkError = "Enter Valid Link Address";
      isValid = false;
    }

    if (webauthenticate === "Yes") {
      if (!onUsername) {
        errors.onUserError = "Enter User Name";
        isValid = false;
      }
      if (!onPassword) {
        errors.onPasswordError = "Enter Password";
        isValid = false;
      }
    }

    setFormState((prevState) => ({ ...prevState, errors }));
    return isValid;
  };

  const submitForm = async () => {
    if (!validateForm()) return;
    setLoading(true);

    const { websiteName, webLink, webtype, webauthenticate, onUsername, onPassword } = formState;
    const domainData = {
      domain_name: websiteName,
      domain_url: webLink,
      domain_type: webtype,
      domain_has_http: webauthenticate === "Yes",
      domain_http_username: onUsername,
      domain_http_password: onPassword,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}domain/add_domain/`, domainData, {
        headers: { Authorization: ApiToken },
      });

      if (response?.data?.status_code === 200) {
        dispatch(SetDomainHeader({ header_selected: response.data.user_domain_id }));
        window.location.href = `${process.env.REACT_APP_DOMAIN}Domainownership`;
      }
    } catch (error) {
      const status = error?.response?.status;
      if ([599, 500, 400, 404].includes(status)) {
        setApiErrorMsg(error?.response?.data?.error);
        setApiOpen(true);
      } else if (error?.code === "ERR_NETWORK") {
        navigate('/errorpages');
      } else {
        setFormState((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, onPageError: error.response.data.error },
        }));
      }
    } finally {
      setLoading(false);
    }
  };

  const { websiteName, webLink, webtype, webauthenticate, showResults, errors } = formState;

  return (
    <div className="userdata">
      <h1>Domain Data</h1>
      <div className="userdata_inner">
        <p>Information about your website...</p>
        <span className="domain-errordata">{errors.onPageError}</span>

        <form className="frm_container">
  <table>
    <tbody>
      {/* Website Name */}
      <tr className="form-group user_country websitename">
        <th style={{ textAlign: 'left', paddingRight: '10px' }}>
          <label htmlFor="websiteName">Website Name:</label>
        </th>
        <td>
          <input
            id="websiteName"
            title="This name is used as an alias to manage your domain."
            type="text"
            name="websiteName"
            value={websiteName}
            onChange={handleChange}
          />
          <span className="domain-errordata">{errors.webnameError}</span>
        </td>
      </tr>

      {/* Website Link */}
      <tr className="form-group user_country websitename">
        <th style={{ textAlign: 'left', paddingRight: '10px' }}>
          <label htmlFor="webLink">Website Link:</label>
        </th>
        <td>
          <input
            id="webLink"
            type="text"
            title="URL of your website (ex: www.example.com)"
            name="webLink"
            value={webLink}
            onChange={handleChange}
          />
          <span className="domain-errordata">{errors.weblinkError}</span>
        </td>
      </tr>

      {/* Website Type */}
      <tr className="form-group websitetype">
        <th style={{ textAlign: 'left', paddingRight: '10px' }}>
          <label>Website Type:</label>
        </th>
        <td>
          <div className="html-js-radio" style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="radio"
              id="html"
              name="webtype"
              value="HTML"
              title="Select if your website is based on HTML or JavaScript."
              checked={webtype === "HTML"}
              onChange={handleChange}
            />
            <label htmlFor="html" style={{ marginRight: '20px', marginLeft: '5px' }}>HTML</label>

            <input
              type="radio"
              id="js"
              name="webtype"
              value="JS"
              title="Select if your website is based on HTML or JavaScript."
              checked={webtype === "JS"}
              onChange={handleChange}
            />
            <label htmlFor="js" style={{ marginLeft: '5px' }}>JS</label>
          </div>
        </td>
      </tr>

      {/* Authentication Requirement */}
      <tr className="form-group websiteauthen">
        <th style={{ textAlign: 'left', paddingRight: '10px' }}>
          <label>Does your website need authentication to access?</label>
        </th>
        <td>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="radio"
              id="yes"
              name="webauthenticate"
              value="Yes"
              title="Does your website need HTTP authentication?"
              checked={webauthenticate === "Yes"}
              onChange={handleChange}
            />
            <label htmlFor="yes" style={{ marginRight: '20px', marginLeft: '5px' }}>Yes</label>

            <input
              type="radio"
              id="no"
              name="webauthenticate"
              value="No"
              title="Does your website need HTTP authentication?"
              checked={webauthenticate === "No"}
              onChange={handleChange}
            />
            <label htmlFor="no" style={{ marginLeft: '5px' }}>No</label>
          </div>
        </td>
      </tr>

      {/* Username and Password (Conditional) */}
      {showResults && (
        <>
          <tr className="authen_approve">
            <th style={{ textAlign: 'left', paddingRight: '10px' }}>
              <label htmlFor="username">Username:</label>
            </th>
            <td>
              <input
                type="text"
                id="username"
                name="onUsername"
                title="User Name"
                value={formState.onUsername}
                onChange={handleChange}
              />
              <span className="domain-errordata">{errors.onUserError}</span>
            </td>
          </tr>
          <tr className="authen_approve">
            <th style={{ textAlign: 'left', paddingRight: '10px' }}>
              <label htmlFor="password">Password:</label>
            </th>
            <td>
              <input
                type="text"
                id="password"
                name="onPassword"
                title="Password"
                value={formState.onPassword}
                onChange={handleChange}
              />
              <span className="domain-errordata">{errors.onPasswordError}</span>
            </td>
          </tr>
        </>
      )}

      {/* Next Button */}
      <tr className="form_next">
        <td colSpan="2" style={{ textAlign: 'center', paddingTop: '20px' }}>
          <button className="nextbutton" type="button" onClick={submitForm} disabled={loading}>
            Next
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</form>


      </div>

      <Dialog open={apiOpen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle>{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{apiErrorMsg}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Domaindata;
