// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-1hsc7hf-MuiDataGrid-root .MuiDataGrid-cellContent {
   overflow-wrap: anywhere !important;
}
.ignore_button_active {
   /* width: 60px; */
   /* height: 33px; */
   padding: 12px;
   background-color: #103658;
   color: white;
   border-radius: 5px;
}
.ignore_button_active:hover{
   background: #fff; 
   color: #fe874e;
   box-shadow: 0px 0px 2px 2px #fe874e;
}

/* svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-ptiqhd-MuiSvgIcon-root {
   display: none;
} */

.spell_checker_detail_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(2)
{
   display: block;
   padding-left: 20px;
}
.spell_checker_detail_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(5)
{
   display: block;
   padding-left: 70px;
 
}

.spell_checker_detail_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(3){
display: flex !important;
}
.spell_checker_detail_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(4){
   display: flex !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/NavBar/spellchecker/spellcheckstyle.css"],"names":[],"mappings":"AAAA;GACG,kCAAkC;AACrC;AACA;GACG,iBAAiB;GACjB,kBAAkB;GAClB,aAAa;GACb,yBAAyB;GACzB,YAAY;GACZ,kBAAkB;AACrB;AACA;GACG,gBAAgB;GAChB,cAAc;GACd,mCAAmC;AACtC;;AAEA;;GAEG;;AAEH;;GAEG,cAAc;GACd,kBAAkB;AACrB;AACA;;GAEG,cAAc;GACd,kBAAkB;;AAErB;;AAEA;AACA,wBAAwB;AACxB;AACA;GACG,wBAAwB;AAC3B","sourcesContent":[".css-1hsc7hf-MuiDataGrid-root .MuiDataGrid-cellContent {\n   overflow-wrap: anywhere !important;\n}\n.ignore_button_active {\n   /* width: 60px; */\n   /* height: 33px; */\n   padding: 12px;\n   background-color: #103658;\n   color: white;\n   border-radius: 5px;\n}\n.ignore_button_active:hover{\n   background: #fff; \n   color: #fe874e;\n   box-shadow: 0px 0px 2px 2px #fe874e;\n}\n\n/* svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-ptiqhd-MuiSvgIcon-root {\n   display: none;\n} */\n\n.spell_checker_detail_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(2)\n{\n   display: block;\n   padding-left: 20px;\n}\n.spell_checker_detail_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(5)\n{\n   display: block;\n   padding-left: 70px;\n \n}\n\n.spell_checker_detail_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(3){\ndisplay: flex !important;\n}\n.spell_checker_detail_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(4){\n   display: flex !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
