import React, { useState } from "react";
import Select from "react-dropdown-select";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Loader from "../CommonLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
const KeywordAddForm = (props) => {
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [CampaignName, SetCampaignName] = useState("");
  const [SectionName, SetSectionName] = useState("");
  const [SearchEngine, SetSearchEngine] = useState("");
  const [Country, SetCountry] = useState("");
  const [State, SetState] = useState("");
  const [City, SetCity] = useState("");
  const [AddDataTableErr, SetAddDataTableErr] = useState("");
  const [AddDataTableArray, SetAddDataTableArray] = useState([]);
  const [FormErrorMessage, SetFormErrorMessage] = useState("");
  const [PreferrredUrl,SetPreferrredUrl]=useState('')
  const [DoaminSectionUrl,SetDoaminSectionUrl]=useState([])
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();

  const [SectionOptions, SetSectionOptions] = useState([]);
  const navigate = useNavigate();


  const apihandleClose = () => {
    setapiOpen(false);
  };
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [Engineoptions,SetEngineoptions]=useState([]);

  const AddDataToTable = () => {
    var Err = 0;

    if (SearchEngine === "") {
      Err = 1;
      SetAddDataTableErr("Please select search engine");
    } else if (Country === "") {
      Err = 1;
      SetAddDataTableErr("Please select country");
    } else if (State === "") {
      Err = 1;
      SetAddDataTableErr("Please select state");
    } else if (City === "") {
      Err = 1;
      SetAddDataTableErr("Please select city");
    } else {
      Err = 0;
      SetAddDataTableErr("");
    }

    if (Err === 0) {
      SetAddDataTableArray((oldArray) => [
        ...oldArray,
        {
          engine: {
            label:SearchEngine.label,
            value:SearchEngine.value
          },
          country:{
            label:Country.label,
            value:Country.value
          },
          state:{
            label:State.label,
            value:State.value
          },
          city:{
            label:City.label,
            value:City.value
          }
        },
      ]);
    }
  };

  const DeleteAddTableArray = (e) => {
    const Id = e.target.closest(".col5").getAttribute("data-id");
    var DeleteArray = AddDataTableArray;
    DeleteArray[Id] = {};

    // Function to check if an object is empty
    const isEmptyObject = (obj) => {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    // Filter out the empty objects from the array
    const filteredArray = DeleteArray.filter((obj) => !isEmptyObject(obj));

    SetAddDataTableArray(filteredArray);
  };

  const IncreseBrandIndex = () => {
    const node = document.getElementsByClassName("branded_term")[0];
    var clone = node.cloneNode(true);

    var clonedInputs = clone.querySelectorAll("input");

    var clonedXButton = clone.querySelectorAll(".IncreseBrandIndexClone");

    clonedXButton.forEach(function (button) {
      button.innerHTML = "";
      var xIcon = document.createElement("i");
      xIcon.className = "fa fa-times brandedtermremove"; // Add the appropriate class for the x icon
      button.appendChild(xIcon); // Add the x icon to the button
    });

    var parentDiv = document.getElementById("branded_term_clone");
    var elements = parentDiv.querySelectorAll("*");
    var numElements = elements.length;

    clonedInputs.forEach(function (input, index) {
      if (input.type === "radio") {
        input.setAttribute("name", "brand_match[" + (numElements + 1) + "]");
      }
      if (input.type === "text") {
        input.setAttribute(
          "name",
          "brand_match_text_" + (numElements + 1) + ""
        );
      }
    });

    document.getElementById("branded_term_clone").appendChild(clone);
  };

  document.body.addEventListener("click", function (event) {
    if (event.target.classList.contains("brandedtermremove")) {
      // Find the closest ancestor element with the class 'branded_term' and remove it
      var brandedTerm = event.target.closest(".branded_term");
      if (brandedTerm) {
        brandedTerm.remove();
      }
    }
  });

  document.body.addEventListener("click", function (event) {
    if (event.target.classList.contains("addkeywordremove")) {
      // Find the closest ancestor element with the class 'branded_term' and remove it
      var brandedTerm = event.target.closest(".add_keywords");
      if (brandedTerm) {
        brandedTerm.remove();
      }
    }
  });

  const IncreseKeywordIndex = () => {
    const node = document.getElementsByClassName("add_keywords")[0];
    var clone = node.cloneNode(true);

    document.getElementById("add_keywords_form_clone").appendChild(clone);

    var clonedXButton = clone.querySelectorAll(".IncreseKeywordIndexClone");

    clonedXButton.forEach(function (button) {
      button.innerHTML = "";
      var xIcon = document.createElement("i");
      xIcon.className = "fa fa-times addkeywordremove"; // Add the appropriate class for the x icon
      button.appendChild(xIcon); // Add the x icon to the button
    });
  };

  const SaveKeyWordCampaign = async() => {
    var Err = 0;
    SetFormErrorMessage("");




    if (CampaignName === "") {
      Err = 1;
      SetFormErrorMessage("Please enter the campaign name");
    } else if (SectionName === "") {
      Err = 1;
      SetFormErrorMessage("Please select section name");
    } else if (AddDataTableArray.length === 0) {
      Err = 1;
      SetFormErrorMessage("Please select atleast one search engine");
    }

    //branding term

    var BrandingTermNameArray = [];
    var BrandingTermMatchArray = [];
    var BrandingMergeArray = [];

    // Get all elements with the 'example' class
    var examples = document.querySelectorAll(".branded_term");

    // Loop through each 'example' element
    examples.forEach(function (example) {
      // Get all the input elements of type text within the current example
      var textInputs = example.querySelectorAll('input[type="text"]');

      // Loop through the retrieved elements and do something with them
      for (var i = 0; i < textInputs.length; i++) {
        BrandingTermNameArray.push(textInputs[i].value);

        // Do something with each input element
      }
    });

    // Get all elements with the 'example' class
    var examples = document.querySelectorAll(".branded_term");

    // Loop through each 'example' element
    examples.forEach(function (example) {
      // Get all the radio button elements within the current example
      var radioInputs = example.querySelectorAll('input[type="radio"]');

      // Loop through the retrieved elements and get the value of each checked input
      radioInputs.forEach(function (input) {
        if (input.checked) {
          BrandingTermMatchArray.push(input.value);

          // Do something with the value of each checked input
        }
      });
    });

    if (BrandingTermNameArray.length === BrandingTermMatchArray.length) {
      BrandingTermNameArray.forEach((element, index) => {
        BrandingMergeArray.push({
          term: BrandingTermNameArray[index],
          match: BrandingTermMatchArray[index],
        });
      });
    }

    //branding term end

    //keyword name

    var examplesk = document.querySelectorAll(".add_keywords");
    var KeywordArray = [];
    var PreferredArray = [];
    var OnpageArray = [];
    var KeywordMergedArray = [];

    // Loop through each 'example' element
    examplesk.forEach(function (example) {
      // Get all the radio button elements within the current example
      var radioInputs = example.querySelectorAll('input[type="text"]');

      // Loop through the retrieved elements and get the value of each checked input
      radioInputs.forEach(function (input) {
        if (input.className === "keyword") {
          KeywordArray.push(input.value);
        }
        if (input.className === "preferred") {
          PreferredArray.push(input.value);
        }
        if (input.className === "onpage") {
          OnpageArray.push(input.value);
        }
      });
    });

    if (KeywordArray.length === PreferredArray.length) {
      KeywordArray.forEach((element, index) => {

         
        if(KeywordArray[index]!=='')
        {
          KeywordMergedArray.push({
            keyword: KeywordArray[index],
            on_page_url: PreferrredUrl+OnpageArray[index],
          });
        }
     
      });
    }

    //keyword name end

    var ConvertedAddDataTableArray=[];
    if(AddDataTableArray)
    {
      AddDataTableArray.forEach(element => {
        ConvertedAddDataTableArray.push({search_engine_id:element.engine.value,city_id:element.city.value})
       });
    }




    if(Err===0)
    {

      try{

        const Response = await axios.post(
          `${process.env.REACT_APP_API_URL}keywords/campaign_add/`,
          {
              user_domain_id: esodata.domaindata.value.header_selected,
              domain_section_id: SectionName.value,
              campaign_name:CampaignName,
              add_keyword:KeywordMergedArray,
              brand_term:BrandingMergeArray,
              search_engine:ConvertedAddDataTableArray
          },
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }       
        ).catch((error) => {
          if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.non_field_errors[0])
          }
          else if(error?.code =="ERR_NETWORK"){
            navigate('/errorpages')    
          }
          // console.log("kannan",error)
        });

        if(Response?.data?.error)
        {

          toast.error(Response.data.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });

        }

      }catch(err)
      {

        if(err.response.data.error)
        {

              
      toast.error(err.response.data.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });

        }

    


        console.log(err)
      }


   
   
      props.SetEnableKeywordAddForm(false)

      props.SetCallCampaignTable(true)
  
    }








  };

  const LoadAddFormFilterOptions = async () => {
    SetSectionOptions([]);
    SetCountryoptions([]);
    SetEngineoptions([]);

    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );

      if (doaminsectiondatavalue.data) {
        if (doaminsectiondatavalue.data.domain_sections) {
          SetDoaminSectionUrl(doaminsectiondatavalue.data.domain_sections)
          var Sections = [];
          doaminsectiondatavalue.data.domain_sections.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetSectionOptions(Sections);
        }
      }
    } catch (err) {}

    try {
      const countrydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_countries/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );

      if (countrydatavalue.data) {
        if (countrydatavalue.data) {
          var Sections = [];
          countrydatavalue.data.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetCountryoptions(Sections);
        }
      }
    } catch (err) {}


        try {
      const countrydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_countries/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );

      if (countrydatavalue.data) {
        if (countrydatavalue.data) {
          var Sections = [];
          countrydatavalue.data.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetCountryoptions(Sections);
        }
      }
    } catch (err) {}


    try {
      const enginedatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/search_engine_locale_list/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
    

      if (enginedatavalue.data) {
        if (enginedatavalue.data) {
          var Sections = [];
          enginedatavalue.data.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetEngineoptions(Sections);
        }
      }
    } catch (err) {}



  };

  const SetCountryHandle = async (value) => {
    SetStateoptions([]);
    if (value) {
      SetCountry(value);

      try{

        const statedatavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}keywords/get_state_by_country/?country_id=${value.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        );
  
        if (statedatavalue.data) {
          if (statedatavalue.data.states) {
            var Sections = [];
            statedatavalue.data.states.forEach((element) => {
              Sections.push({ label: element.state_name, value: element.id });
            });
            SetStateoptions(Sections);
          }
        }

      }catch(err)
      {
        console.log(err)
      }

   
    }
  };

  const SetStateHandle = async (value) => {
    SetCityOptions([]);
    if (value) {
      SetState(value);

      const citydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_cities_by_state/?state_id=${value.value}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );

      if (citydatavalue.data) {
        if (citydatavalue.data.cities) {
          var Sections = [];
          citydatavalue.data.cities.forEach((element) => {
            Sections.push({ label: element.city_name, value: element.id });
          });
          SetCityOptions(Sections);
        }
      }
    }
  };

  const SetSectionNameHandle=(passvalue)=>{


    SetSectionName(passvalue)




    var ObjectFound=findObjectById(DoaminSectionUrl,parseInt(passvalue.value))
    if(ObjectFound)
    {

      SetPreferrredUrl(ObjectFound.url)
    }

    

  }


  const findObjectById = (array, id) => {
    return array.find(obj => obj.id === id);
  };

  useEffect(() => {
    LoadAddFormFilterOptions();
  }, []);

  return (
    <div className="form">

<ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
     
      <div className="two_col">
        <div className="col1">
          <div className="form-label">Campaign Name</div>
          <input
            className="form-control"
            type="text"
            onChange={(e) => {
              SetCampaignName(e.target.value);
            }}
          />
        </div>
        <div className="col2">
          <label className="form-label">Section Name</label>
          <Select
            options={SectionOptions}
            onChange={(e) => {
              SetSectionNameHandle(e[0]);
            }}
          />
        </div>
      </div>

      <div className="four_col">
        <div className="col">
          <label className="form-label">Search Engine</label>
          <Select
            options={Engineoptions}
            onChange={(e) => {
              SetSearchEngine(e[0]);
            }}
          />
        </div>
        <div className="col">
          <label className="form-label">Country</label>
          <Select
            options={Countryoptions}
            onChange={(e) => {
              SetCountryHandle(e[0]);
            }}
          />
        </div>
        <div className="col">
          <label className="form-label">State</label>
          <Select
            options={Stateoptions}
            onChange={(e) => {
              SetStateHandle(e[0]);
            }}
          />
        </div>
        <div className="col">
          <label className="form-label">City</label>
          <Select
            options={CityOptions}
            onChange={(e) => {
              SetCity(e[0]);
            }}
          />
        </div>
        <div className="col button_col">
          <button className="click_to_add" onClick={AddDataToTable}>
            Click To Add
          </button>
        </div>
      </div>
      <div style={{ color: "red" }}>{AddDataTableErr}</div>
      <div className="adddatatotable">
        {AddDataTableArray.map((data, index) => (
          <div className="row">
            <div className="col">{data.engine.label}</div>
            <div className="col">{data.country.label}</div>
            <div className="col">{data.state.label}</div>
            <div className="col">{data.city.label}</div>
            <div
              className="col col5"
              data-id={index}
              onClick={(e) => {
                DeleteAddTableArray(e);
              }}
            >
              <CloseIcon />
            </div>
          </div>
        ))}
      </div>

      <div className="branded_term">
        <div className="row">
          <div className="colhead brandhead">
            <div className="col1">Branded Term :</div>
            <div className="col2">
              <input
                name="brand_match_text_0"
                className="form-control"
                type="text"
              />
            </div>
          </div>

          <div className="colhead inputhead">
            <input type="radio" name="brand_match[]" value={"phrase"} />
            Phrase Match
            <input type="radio" name="brand_match[]" value={"exact"} />
            Exact Match
            <span
              className="IncreseBrandIndexClone"
              onClick={IncreseBrandIndex}
            >
              <AddIcon />
            </span>
          </div>
        </div>
      </div>
      <div id="branded_term_clone"></div>

      <div className="add_keywords_parent">
        <div className="add_key_txt" style={{ marginBottom: "20px" }}>Add Keywords </div>

        <div className="add_keywords_form">
          <div className="add_keywords">
            <div className="col">
              <div className="label">Keyword Name</div>
              <div className="input">
                <input type="text" className="keyword" />
              </div>
            </div>

            <div className="col">
              <div className="label">Preferred URL</div>
              <div className="input">
                <input
                  disabled
                  type="text"
                  className="preferred"
                  value={PreferrredUrl}
                />
              </div>
            </div>

            <div className="col">
              <div className="label">On Page Url</div>
              <div className="input">
                <input type="text" className="onpage" />
              </div>
              <span
                className="IncreseKeywordIndexClone"
                onClick={IncreseKeywordIndex}
              >
                <AddIcon />
              </span>
            </div>
          </div>
        </div>

        <div id="add_keywords_form_clone"></div>
      </div>

      <div className="form_error_msg">{FormErrorMessage}</div>

      <div className="form_button">
        <button className="greenbutton" onClick={SaveKeyWordCampaign}>
          Save
        </button>
        <button className="redbutton" onClick={()=>{props.SetEnableKeywordAddForm(false)}}>Cancel</button>
      </div>
      <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
    </div>
  );
};
export default KeywordAddForm;
