import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Logindetail.css";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import copy from "copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
function Domainownership (){
  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken=esodata.apiauthkey.value
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  const apihandleClose = () => {
    setapiOpen(false);
  };
  useEffect(() => {
    callUserdataApi();
  }, []);
  const nextButtonClick = async () =>{

    try{

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/ownership_token_verify/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK"){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });

    navigate('/Googleanalytics') 

    }catch(err)
    {

      if(err.response.data.status_code===408)
      {


        toast.error(err.response.data.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });

          setTimeout(() => {
            window.location.reload();
          }, 2000);


      }
      else{

        toast.error(err.response.data.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });

      }
      
    }
   


  }
  const handleCopyClick  = () => {
      copy(text);
      toast.success('Successfully copied to clipboard', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false, 
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
};
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    try {
      const domain_owber = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/ownership_token_generate/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK"){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      setText(domain_owber?.data?.domain_verification_token)
    
    } catch (err) {}
    setLoading(false);
  };
    return (
      <div className="userdata">
          <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
        <h1>Domain Ownership Verification</h1>
        <div className="userdata_inner">
        <p>Verify your website ownership...</p>    

        <div class="form-group domain_owner">
          <label>1) Sign in to your domain name provider (e.g. godaddy.com or namecheap.com)</label>
        </div>
        <div class="form-group domain_owner domain_ownertext domain_input_row">
            <label>2) Copy the TXT record below into the DNS configuration for your website</label>
            <label>3) Press Next button below</label>
            <input type="text"  value={text} onChange={(e) => setText(e.target.value)} disabled/>
            <button className="copybutton" onClick={handleCopyClick}>
                Copy 
            </button>
        </div>
        <div className="domain_input_row_note">
       Note: Usually after changing TXT record will take effect only after 60 secs
        This token will expire in 10 mins
        </div>
    

     
         
        
         <div className="form_next">      
            <button className="nextbutton" type="button" onClick={nextButtonClick}>Next</button>
         </div>   
     
        </div>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>   

    );
}

export default Domainownership;


