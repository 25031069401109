import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import axios from "axios";
import "./../style.css";
import { Bar } from "react-chartjs-2";



import ReactApexChart from "react-apexcharts";


export default function SuccessChart() {
  const [seoindexData, setSeoindexData] = useState([]);
  const [seooccurData, setSeooccurData] = useState([]);

  setSeoindexData(["Success","Failure"])
  setSeooccurData([5,7]);
  const barChartData = {
    labels: [5,7],
    datasets: [
      {
        label: "Earlier Occurence",
        data: ["Success","Failure"],
        barPercentage: 0.1,
        categoryPercentage: 4,
        borderWidth: 0,
        backgroundColor: "#FE6020",
      },
    ],
  };
  return (
   <>
   <Bar
                  type="bar"
                  width={40}
                  height={30}
                  data={barChartData}
                  options={{
                    responsive: true,
                    plugins: {
                      // 'legend' now within object 'plugins {}'
                      legend: {
                        display: false,
                      },
                      title: {
                        display: true,
                        text: "Site Audit Summary by Issues",
                        color: "#0b2033",
                        font: {
                          size: 18,
                          family:'Open Sans',
                        },
                      },
                    },
                    scales: {
                      y: {
                        // not 'yAxes: [{' anymore (not an array anymore)
                        ticks: {
                          suggestedMin: 0,
                          stacked: true,
                          color: "#0b2033", // not 'fontColor:' anymore
                          // fontSize: 18,
                          font: {
                            size: 14, // 'size' now within object 'font {}'
                            family:'Open Sans',
                          },
                          beginAtZero: true,
                        },
                      },
                      x: {
                        // not 'xAxes: [{' anymore (not an array anymore)
                        ticks: {
                          color: "#0b2033", // not 'fontColor:' anymore
                          //fontSize: 14,
                          font: {
                            size: 12, // 'size' now within object 'font {}'
                            family:'Open Sans',
                          },
                          beginAtZero: true,
                        },
                      },
                    },
                  }}
                />
   </>
  );
}
