import React, { useState, useEffect ,useRef} from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import axios from "axios";
import "./style.css";
import calendarpng from "./Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./Asset/PDF.png";
import csvicon from "./Asset/CSV.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./Asset/New_Assert/ESO_ICON.png";
import Select from "react-dropdown-select";
// import Select from "react-select";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { Tooltip } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import SquareIcon from "@mui/icons-material/Square";
import RunReportNow from "./RunReportNow";
import { Domain } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "./Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "./Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import { Commonfun } from './Commonfun';


export default function Auditsummary() {
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [value, onChange] = React.useState();
  const [valueOutside, onChangeOutsidde] = React.useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([]);
  const [Domainsectiondata, SetDomainsectiondata] = useState([]);
  const [initialDateval, SetInitialDateval] = useState([]);
  const [initialfaqDateval,SetInitialfaqDateval] = useState([]);
  const [autoheightval,SetAutoheightval]=useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
const [apierrorMsg,setapierrorMsg]=useState();
  const [pageSize, setPageSize] = React.useState(15);
  const [dEngineoption, SetdEngineoption] = useState({
    completed_at: "Select Date",
    id: "Select Date",
  });
  
  const [faqData,setfaqData]=useState("2")

  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const OnSetSelectedWcagType = (values) => {
    SetInitialDateval(values);
    setSelectval(values.value);
  };
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}content-detail/`;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const arr = [];

  useEffect(() => {
    callUserdataApi();
    LoadAddFormFilterOptions();
    faqdatafun()
  }, []);
  const LoadAddFormFilterOptions = async () => {
    SetSectionOptions([]);
    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
        if (doaminsectiondatavalue.data.domain_sections) {
          SetDoaminSectionUrl(doaminsectiondatavalue.data.domain_sections);
          var Sections = [];
          doaminsectiondatavalue.data.domain_sections.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetDomainsectiondata(Sections[0].value);
          SetSectionOptions(Sections);
        }
      }
    } catch (err) {}
  };
  const columns = [
    {
      field: "sid",
      headerName: "#",
      width: 70,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "link__url",
      headerName: "Web Page URL",
      description: "URL of the web page.",
      width: 220,

      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {
        let firstEightLetters = "";
        let lastThreeLetters = "";
        let whoedata = "";
        if (cardData.row.link__url.length > 20) {
          firstEightLetters = cardData.row.link__url.substring(0, 20);
          lastThreeLetters = cardData.row.link__url.slice(-5);
          whoedata = firstEightLetters + "..." + lastThreeLetters;
        } else {
          firstEightLetters = cardData.row.link__url;
          whoedata = firstEightLetters;
        }

        return (
          <>
            <Tooltip title={cardData.row.link__url}>
              <div className="prefered_url_external">
                <LinkIcon />
                <a target="_blank" href={cardData.row.link__url}>
                  {whoedata}
                </a>
              </div>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "read_score",
      headerName: "Readability Score",
      description: "Readability score of the web page.",
      width: 110,
      headerAlign: "center",
      align: "center",
      type: "number",
      renderCell: (cardData) => {
        var ColorToRender = "";
        var fleschScore = cardData.row.read_score;

        // Check Flesch reading ease score
        if (fleschScore >= 90 && fleschScore <= 100) {
          ColorToRender = "#006400";
        } else if (fleschScore >= 80 && fleschScore < 90) {
          ColorToRender = "#008000";
        } else if (fleschScore >= 70 && fleschScore < 80) {
          ColorToRender = "#FFA500";
        } else if (fleschScore >= 60 && fleschScore < 70) {
          ColorToRender = "#FFD700";
        } else if (fleschScore >= 50 && fleschScore < 60) {
          ColorToRender = "#D2B48C";
        } else if (fleschScore >= 30 && fleschScore < 50) {
          ColorToRender = "#A52A2A";
        } else {
          ColorToRender = "#FF0000"; // or any other default color
        }

        return (
          <div
            class="MuiDataGrid-cellContent read_score_org"
            title={`${cardData.row.read_score}`}
            role="presentation"
            style={{ background: ColorToRender }}
          >{`${cardData.row.read_score}`}</div>
        );
      },
    },
    {
      field: "read_ease",
      headerName: "Reading Easiness",
      description: "Reading ease level of the content of the web page.",
      width: 90,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "grad_level",
      headerName: "Grade Level",
      description:
        "Grade level is required for an average reader to understand the page content.",
      width: 100,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "number_of_sentances",
      headerName: "Total Sentences",
      description: "The total number of sentences present on the web page.",
      width: 110,
      headerAlign: "center",
      align: "center",
      type: "number",
    },
    {
      field: "total_words",
      headerName: "Total Words",
      description: "The total number of words present on the web page.",
      width: 80,
      headerAlign: "center",
      align: "center",
      type: "number",
    },
    {
      field: "avg_sentance_count",
      headerName: "Avg Sentence Length",
      description: "Average sentence length on the web page.",
      width: 100,
      headerAlign: "center",
      align: "center",
      type: "number",
    },
    {
      field: "search_quries",
      headerName: "Search Queries",
      description:
        "The total number of search terms or keywords through which the user has reached the web page.",
      width: 90,
      headerAlign: "center",
      align: "center",
      type: "number",
    },
    {
      field: "clicks",
      headerName: "Clicks",
      description:
        "Every click by the viewer on the website available in the organic search results is counted as a click.",
      width: 70,
      headerAlign: "center",
      align: "center",
      type: "number",
    },
    {
      field: "impression",
      headerName: "Impression",
      description:
        "It is counted each time your ad or webpage link is shown on a search result page",
      width: 115,
      headerAlign: "center",
      align: "center",
      type: "number",
    },
    {
      field: "session",
      headerName: "Sessions",
      description:
        "The number of individual sessions started by a user. One session lasts up to 30 seconds",
      width: 90,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "bounce_rate",
      headerName: "Bounce Rate",
      description:
        "The number of visits in which a person leaves your web page from the landing page without browsing any further",
      width: 80,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "avg_time_onpage",
      headerName: "Avg Session Time",
      description: "The average time a user spends on the webpage.",
      width: 90,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "",
      headerName: "View",

      width: 100,
      headerAlign: "center",
      align: "center",
      sortable: false,
      headerClassName: "hide_option",
      renderCell: (cardData) => {
        return (
          <a href={detailurl + `${cardData.row.id}`}>
            <img
              src={fav_icon}
              className="view_td_img"
              alt="view report icon"
            />
          </a>
        );
      },
    },
  ];

  const rows = cardData ?? [];
  const SetSectionNameHandle = (passvalue) => {
    SetDomainsectiondata(passvalue[0].value);
    callUserdataApi("", passvalue[0].value);
  };
  const callUserdataApi = async (jobid = "", domainsecdata = "") => {
  
    setLoading(true);
    if (domainsecdata == "") domainsecdata = Domainsectiondata;
    if (jobid == "") jobid = jobidData;
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}content/v2/analysis_summary/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobid}&domain_section_id=${domainsecdata}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if (jobidData == "") {
        const completevalues = await axios.get(
          `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=analysis_summary&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
            navigate('/errorpages')    
          }
          // console.log("kannan",error)
        });
        var Selectedmets = [];

        completevalues?.data?.jobs.forEach((element) => {
          Selectedmets.push({
            id: element.id,
            completed_at: element.completed_at,
          });
        });
        SetInitialDateval(Selectedmets[0]);
        console.log("kananahiifsfdsj",Selectedmets[0])
        setSelectval(Selectedmets[0]);
        SetdEngineoption(Selectedmets[0]);
        setcompletedate(Selectedmets);
      }
      const dataval = datavalue?.data?.table_data;
    
      setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY "));
    
      if (dataval && dataval.length>0) {
        const newData = dataval.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });
      
        SetAutoheightval(false)
        setCardData(newData);
      }
      else{
        SetAutoheightval(true)
        setCardData("");
        setjobDate();
      }
    } catch (err) {
      setCardData("");
      console.log("kanerror",err.code)
      if(err.code =="ERR_NETWORK"){
        navigate('/*')
      }
   
  
    }

    setLoading(false);
  };

  const reportNow = async () => {
    console.log("selectval",selectval)
    var errs = 1;
    let errors = {};

    if (selectval[0]?.id == "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }
    setLoading(true);
    setjobidData(selectval[0]?.id);
    closeModal();
    var domainsec = "";
    callUserdataApi(selectval[0]?.id, (domainsec = ""));
  };
  function openModal() {
    setfaqData(2)
    setToggle(false)
    setIsOpen(true);
  }
  function closeModal() {
    setSelectval("");
    setIsOpen(false);
  }
  const pdfdown = async () => {
    setLoading(true);
    try {
      const response = await fetch(
       
        `${process.env.REACT_APP_API_URL}content/v2/analysis_summary/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&job_id=${jobidData}&domain_section_id=${Domainsectiondata}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            Authorization: `${ApiToken}`,
          },
        }
      );
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var filedata=await Commonfun("AnalysisSummary","pdf", ApiToken,esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;

        // Trigger a click on the anchor element to start the download
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        // toast.error("No Data", {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
        // console.error(
        //   "Failed to fetch PDF:",
        //   response.status,
        //   response.statusText
        // );
        if(response?.status==599 || response?.status==500 || response?.status==400  || response?.status==404 || response?.status==502){
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if(response?.code =="ERR_NETWORK"){
          navigate('/errorpages')    
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error("Error during PDF download:", error);
    }
    setLoading(false);
  };
  const csvdown = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}content/v2/analysis_summary/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=csv&job_id=${jobidData}&domain_section_id=${Domainsectiondata}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            Authorization: `${ApiToken}`,
          },
        }
      );
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        const now = new Date();
        const datetimeval=now.toLocaleDateString();
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
        var filedata=await Commonfun("AnalysisSummary","csv", ApiToken,esodata.domaindata.value.header_selected);
        link.download = filedata;

        // Trigger a click on the anchor element to start the download
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        // toast.error("No Data", {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
        // console.error(
        //   "Failed to fetch PDF:",
        //   response.status,
        //   response.statusText
        // );
        if(response?.status==599 || response?.status==500 || response?.status==400  || response?.status==404 || response?.status==502){
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if(response?.code =="ERR_NETWORK"){
          navigate('/errorpages')    
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error("Error during PDF download:", error);
    }
    setLoading(false);
  };
  const [RunReportPopShow, SetRunReportPopShow] = useState(false);
  const [RunReportType, SetRunReportType] = useState("analysis_summary");
  const [toggle, setToggle] = useState(false);
  const wrapperRef = useRef(null);
  const faqdatafun = async(menubutval="") => {
    var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=11`
  
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
       console.log("domain data",doaminsectiondatavalue?.data?.faqs)
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) {}
  }

  const handleToggle = () => {
    if(faqData!=1){
      setfaqData(1)
    }
    else{
      setfaqData(2)
    }
        setToggle((pre) => !pre);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') || 
    (event.target.classList[0] === 'nav_key_txt') || 
    (event.target.classList[0] === 'cnt_hdr_top_wrap') ||(event.target.classList[0] === 'react-dropdown-select') 
    ||(event.target.classList[0] === 'react-dropdown-select-content') 
    ||(event.target.classList[0] === 'react-dropdown-select-item') 
    || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap')   || (event.target.classList[0] === 'App')  
    || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
     || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row') 
     || (event.target.classList[0] === 'MuiDataGrid-cell')  || (event.target.classList[0] === 'MuiDataGrid-cellContent')
     || (event.target.classList[0] === 'header_inner_btm_wrap')||(event.target.classList[0] === 'over_all_container') 
     ||(event.target.classList[0] === 'input_domain_field') ||(event.target.classList[0] === 'react-dropdown-select-input') ) {
      setfaqData(2)
      setToggle(false)   
    }
});
  return (
    <div className="over_all_container  over_all_accessibility">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Analysis Summary</title>
      </Helmet>
      <div
        style={{
          width: "calc(100% - 40px)",
          marginRight: "40px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
      <div className="cnt_audit_outer_wrap">
        <div className="cnt_hdr_top_wrap">
          <div className="cnt_hdr_top_lft_wrap">
            <h2>Analysis Summary</h2>
            <p>as of {jobDate}</p>
          </div>
          <div className="cnt_hdr_top_rgt_wrap">
          <div className={`App ${faqData=="1"?'faq_move_class':""}`} >
            <button onClick={handleToggle} className="faq_class"  title="Frequently Asked Question" >
            <QuestionMarkIcon />
              
            </button>
           {toggle && <Rightsidebar  close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />} 
          </div>
            <img src={pdficon} alt="pdf icon" onClick={pdfdown} title="PDF Download"/>
            <img src={csvicon} alt="csv icon" onClick={csvdown} title="CSV Download"/>
          </div>
        </div>

        <div className="cnt_hdr_blw_wrap">
          <button
           title="Run Report"
            className="run_rpt_btn rn_rpt_btn over_all_accessibility"
            onClick={() => {
              setfaqData(2)
              setToggle(false)   
              SetRunReportPopShow(true);
            }}
          >
            Run Report Now
          </button>
          <button className="run_rpt_btn ch_date_btn over_all_accessibility" onClick={openModal}  title="Filter">
            Filter By
            <span className="_btn_icon_calender">
              <img src={calendarpng} alt="calender logo" />
            </span>
          </button>
          {SectionOptions?.length > 0 && (
            <div className="listdropdown over_all_accessibility">
              <div title="Domain Section">
                <span className="listdropdown_titl">Domain Section</span>

                <Select
                
                  className="drop-organicsearch"
                  name="name"
                  isClearable
                  onChange={SetSectionNameHandle}
                  options={SectionOptions}
                  isSearchable="true"
                  placeholder="Home Page"
                />
              </div>
            </div>
          )}

          
        </div>

        <div
          className="data_table analysis_summary_table analysed_table_Data over_all_accessibility"
          style={{ width: "100%" }}
        >
          <div style={{ height: 550, width: "100%" }} className="over_all_accessibility">
            <DataGrid
              slots={{
                noRowsOverlay: MyCustomNoRowsOverlay,
                toolbar: GridToolbar,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              rows={rows}
              columns={columns}
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal",
                },
                "&, [class^=MuiDataGrid]": { border: "none" },
                ".MuiDataGrid-virtualScroller": {
                  height: "auto !important",
                  overflowY: "auto",
                },
                "& .MuiDataGrid-cell:first-child": {
                  position: "sticky",
                  top: "0",
                  left: "0",
                  paddingLeft: "1.5rem",
                  zIndex: 999,
                },
                "& .MuiDataGrid-columnHeader:first-child": {
                  position: "sticky",
                  top: "0",
                  left: "0",
                  paddingLeft: "1.5rem",
                  border: "none",
                  zIndex: 999,
                },
              }}
              getRowHeight={() => "auto"}
              autoHeight={autoheightval?true:false}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              loading={loading}
              pageSizeOptions={[10, 30, 50, 75, 100]}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              className="over_all_accessibility"
            />
     
          </div>
        </div>

        <div className="below_note_ranking icon over_all_accessibility">
          {/* Very easy - Dark Green */}
          <div style={{ color: "#006400" }}>
            {" "}
            <SquareIcon />- <span style={{ color: "#0b2033" }}>Very easy</span>
          </div>

          {/* Easy - Green */}
          <div style={{ color: "#008000" }}>
            {" "}
            <SquareIcon />- <span style={{ color: "#0b2033" }}>Easy</span>
          </div>

          {/* Fairly easy - Orange */}
          <div style={{ color: "#FFA500" }}>
            {" "}
            <SquareIcon />-{" "}
            <span style={{ color: "#0b2033" }}>Fairly easy</span>
          </div>

          {/* Okay - Light Orange */}
          <div style={{ color: "#FFD700" }}>
            {" "}
            <SquareIcon />- <span style={{ color: "#0b2033" }}>Okay</span>
          </div>

          {/* Fairly difficult - Light brown */}
          <div style={{ color: "#D2B48C" }}>
            {" "}
            <SquareIcon />-{" "}
            <span style={{ color: "#0b2033" }}>Fairly difficult</span>
          </div>

          {/*  Difficult -  brown */}
          <div style={{ color: "#A52A2A" }}>
            {" "}
            <SquareIcon />- <span style={{ color: "#0b2033" }}>Difficult</span>
          </div>

          {/* Very Confusing - Light brown */}
          <div style={{ color: "#FF0000" }}>
            {" "}
            <SquareIcon />-{" "}
            <span style={{ color: "#0b2033" }}>Very Confusing</span>
          </div>
        </div>

        <Dialog
          open={modalIsOpen}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="competitor_test_filter job_date_filter over_all_accessibility"
        >
          <DialogTitle>{"Choose The Date"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="cnt_hdr_blw_wrap row">
                <div>
                  <div className="">
                    <div>
                      <span>Completed Date List</span>
                      <Select
                        values={[dEngineoption]}
                        options={completedate}
                        labelField="completed_at"
                        valueField="id"
                        onChange={(values) => {
                          setSelectval(values);

                          SetdEngineoption({
                            completed_at: values[0].completed_at,
                            id: values[0].id,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="col">
                    <span className="text-error">{validation.dateerror}</span>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              className="run_rpt_btn rn_rpt_btn"
              onClick={reportNow}
              style={{ cursor: "pointer" }}
            >
              Submit
            </button>
            <button style={{ cursor: "pointer" }} onClick={closeModal}>
              close
            </button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Error Message "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              No Data Found
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
      </div>
      <RunReportNow
        RunReportPopShow={RunReportPopShow}
        SetRunReportPopShow={SetRunReportPopShow}
        RunReportType={RunReportType}
      />
    </div>
  );
}
