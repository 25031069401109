// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userdatas {
    text-align: center;
    float: left;
    width: 100%;
    padding-bottom: 3em;
}
.userdatas h1 {
    padding: 30px 0px;
    text-align: center;
    margin: 0px;
    font-size: 36px;
    letter-spacing: 1px;
    color: #FFFFFF;
    opacity: 1;
}
.userdatas_inner p {
    font-size: 22px;
    color: #fff;
}
.userdatas_inner {
    background: #fe874e 0% 0% no-repeat padding-box;
    margin: 0 auto;
    text-align: center;
    max-width: 66%;
    border-radius: 17px;
    padding: 1em 0em 2em;
}

`, "",{"version":3,"sources":["webpack://./src/pages/NavBar/UserDomainList/DomainLogindetail.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,cAAc;IACd,UAAU;AACd;AACA;IACI,eAAe;IACf,WAAW;AACf;AACA;IACI,+CAA+C;IAC/C,cAAc;IACd,kBAAkB;IAClB,cAAc;IACd,mBAAmB;IACnB,oBAAoB;AACxB","sourcesContent":[".userdatas {\n    text-align: center;\n    float: left;\n    width: 100%;\n    padding-bottom: 3em;\n}\n.userdatas h1 {\n    padding: 30px 0px;\n    text-align: center;\n    margin: 0px;\n    font-size: 36px;\n    letter-spacing: 1px;\n    color: #FFFFFF;\n    opacity: 1;\n}\n.userdatas_inner p {\n    font-size: 22px;\n    color: #fff;\n}\n.userdatas_inner {\n    background: #fe874e 0% 0% no-repeat padding-box;\n    margin: 0 auto;\n    text-align: center;\n    max-width: 66%;\n    border-radius: 17px;\n    padding: 1em 0em 2em;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
