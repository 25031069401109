import React, { useState, useEffect } from "react";
import axios from "axios";
import "./style.css";
import pdficon from './../Asset/PDF.png'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment';
import fav_icon from './../Asset/New_Assert/ESO_ICON.png';
import { red } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Loader from "../CommonLoader";
import { Commonfun } from './../Commonfun';
  export default function Schemadetail() {
    const [cardData, setCardData] = useState([]);
    const [jobidData, setjobidData] = useState("");
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(new Date());
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [modalIsOpen, setIsOpen] = React.useState(false);
  const [value, onChange] = React.useState();
  const [valueOutside, onChangeOutsidde] = React.useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = React.useState(false);
 const [domainsecdata,setDomainsecdata] =useState("")
 const [apiopen, setapiOpen] = React.useState(false);
 const [apierrorMsg,setapierrorMsg]=useState();
 
 const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken=esodata.apiauthkey.value

  var hasNumber = /\d/;   


  const [jobDate, setjobDate] = useState("");

  let querystring = useParams();
  let plag_id = querystring.id;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const [validation, setValidation] = useState({
    dateerror: ""
  });
  let errors = { ...validation };
  let subtitle;
  

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

/* */
useEffect(() => {

  setTimeout(() => {

    const keyElements = document.querySelectorAll('.schemaOutputeso .key_val_row .key');

    keyElements.forEach(element => {
      // Check if the content of the element is not empty
      if (element.textContent.trim() !== '') {
        // Add a new class if the content is not empty
        element.classList.add('newClass');
      }
    });
    
  }, 2000);

}, []);
/* */

  const arr = [];

  useEffect(() => {
    callUserdataApi();
  }, []);
  const columns = [
   
    {
      field: 'links',
      headerName: 'Page Url',
      width: 600,
      sortable: false,
      disableClickEventBubbling: true,
    },
    {
      field: 'results',
      headerName: 'Status',
      width: 300,
      renderCell: (cardData) => {  if(cardData.row.result == "failed"){ return ( <p className="read_score_org_faile" style={{color:red}}>{`Fail`}</p> ); } else{ return ( <p className="read_score_gry_success">{`Pass`}</p> ); } }
    },
    {
      field: '',
      headerName: 'View',
      width: 300,
      renderCell: (cardData) => { return (<button className="buttonView">View Report <img src={fav_icon}  className="view_std_img" alt="view report icon" /> </button>); }
    },
   
  ];
  
  const rows = cardData


  const [SchemaUrl,SetSchemaUrl]=useState('')
  const [ErrorCount,SetErrorCount]=useState(0)
  const [WarningCount,SetWarningCount]=useState(0)





  const callUserdataApi = async () => {
    setLoading(true);

    SetSchemaUrl('')
    SetErrorCount(0)
    SetWarningCount(0)

   try{
    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}schema/schema_detaildata/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=&id=${plag_id}`,{
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });
    if(datavalue?.data){
      const dataval = datavalue?.data;
      console.log(dataval)
      setCardData(dataval);  
      SetSchemaUrl(dataval?.table_data[0]?.link__url)
      SetErrorCount(dataval?.table_data[0]?.error_count)
      SetWarningCount(dataval?.table_data[0]?.warning_count)    
      setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY "));
      var schemaOutputElement = document.getElementById('schemaOutput');
       schemaOutputElement.innerHTML = generateSchemaHTML(dataval?.table_data[0]?.schema_error_json);
    }
 
    } catch{

    }
    setLoading(false);
  };
  
  const reportNow =  async () => {
    setLoading(true);
    var errs=1
    let errors = {};    
     if(startDate =="" ||endDate =="" ){
        errors.dateerror = "This field is required.";
        errs=2
     }
     else {
      errors.dateerror = "";
      errs=1
      setValidation(errors);
    }
    if(errs==2){
      return setValidation(errors);
    }
    var start_date=moment(startDate).format('YYYY-MM-DD')
    var end_date=moment(endDate).format('YYYY-MM-DD')
   
    const datavalue =  await axios.get(
      `${process.env.REACT_APP_API_URL}content/analysis_summary_crawl_dates/?start_date=${start_date}&end_date=${end_date}` ,{
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });
    if(datavalue?.data?.result?.length > 0){
      const dataval =datavalue?.data?.result[0]?.job_id;
      setjobidData(dataval);  
      callUserdataApi()

    }
   else{
    handleClickOpen()
   }
   
  }
 

  function openModal() {
    setIsOpen(true);
  }



  function closeModal() {
    setIsOpen(false);
  }


  function generateSchemaHTML(schemaData) {




    var output = "<ul>";
    for (var key in schemaData) {
      if (typeof schemaData[key] === 'object') {
        output += "<li class='parent'>" + '' + " " + generateSchemaHTMLChild(schemaData[key]) + "</li>";
      } else {

       
        if(key==='required')
        {
    
          output += "<li style='color:#ffae1f'><div data-key="+key+" class='key_val_row'><div class='key'></div> <div class='val'>" + schemaData[key] + "</div></div></li>";
        }
        else if(key==='recommended')
        {
    
          output += "<li style='color:#3547d3'><div data-key="+key+" class='key_val_row'><div class='key'></div> <div class='val'>" + schemaData[key] + "</div></div></li>";
        }
        else{

          if(key.startsWith("warning_"))
          {
          output += "<li style='color:#3547d3'><div data-key="+key+" class='key_val_row'><div class='key'></div> <div class='val'>" + schemaData[key] + "</div></div></li>";
          }else{

            if( hasNumber.test(key))
            {
              output += "<li><div data-key="+key+" class='key_val_row'><div class='key'></div> <div class='val'>" + schemaData[key] + "</div></div></li>";
            }
            else{
              output += "<li><div data-key="+key+" class='key_val_row'><div class='key'>" + key + "</div> <div class='val'>" + schemaData[key] + "</div></div></li>";
            }
          
          }
        }
   
      }
    }
    output += "</ul>";
    return output;
  }

  function generateSchemaHTMLChild(schemaData){

    var output = "<ul class='child'>";
    for (var key in schemaData) {
      if (typeof schemaData[key] === 'object') {

        if(key==='required')
        {
          output += "<li style='color:#ffae1f' data-key="+key+">" + generateSchemaHTMLChild(schemaData[key]) + "</li>";
        }
        else if(key==='recommended')
        {
          output += "<li style='color:#3547d3' data-key="+key+">" + generateSchemaHTMLChild(schemaData[key]) + "</li>";
        }
        else{

          if(key.startsWith("warning_"))
          {
            output += "<li style='color:#3547d3' data-key="+key+"> " + generateSchemaHTMLChild(schemaData[key]) + "</li>";
          }
          else{

            if( hasNumber.test(key))
            {
            output += "<li  data-key="+key+"> " + generateSchemaHTMLChild(schemaData[key]) + "</li>";
            }else{
              output += "<li  data-key="+key+"> <span class='tle_key'>" + key + "</span> " + generateSchemaHTMLChild(schemaData[key]) + "</li>";
            }
          }


 
        }


     
      } else {



        if(key==='required')
        {
    
          output += "<li style='color:#ffae1f' data-key="+key+"><div class='key_val_row'><div data-key="+key+" class='key'></div> <div class='val'>" + schemaData[key] + "</div></div></li>";
        }
        else if(key==='recommended')
        {
    
          output += "<li style='color:#3547d3' data-key="+key+"><div class='key_val_row'><div data-key="+key+" class='key'></div> <div class='val'>" + schemaData[key] + "</div></div></li>";
        }
        else{

          if(key.startsWith("warning_"))
          {
          output += "<li style='color:#3547d3' data-key="+key+"><div class='key_val_row'><div data-key="+key+" class='key'></div> <div class='val'>" + schemaData[key] + "</div></div></li>";
          }
          else{

            if( hasNumber.test(key))
            {
            output += "<li data-key="+key+"><div class='key_val_row'><div data-key="+key+" class='key'></div> <div class='val'>" + schemaData[key] + "</div></div></li>";
            }
            else{
              output += "<li data-key="+key+"><div class='key_val_row'><div data-key="+key+" class='key'>" + key + "</div> <div class='val'>" + schemaData[key] + "</div></div></li>";
            }
          }
        }



    
      }
    }
    output += "</ul>";
    return output;

  }
  const pdfdown = async () =>{ 

    setLoading(true);
    try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}schema/schema_detaildata/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobidData}&id=${plag_id}&domain_section_id=${domainsecdata}&formats=pdf`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();
  
                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                var filedata=await Commonfun("SchemaDetail","pdf", ApiToken,esodata.domaindata.value.header_selected);

                link.download = filedata;
                link.click();
                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              toast.error("No Data", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
    
                console.error('Failed to fetch PDF:', response.status, response.statusText);
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });

            console.error('Error during PDF download:', error);
        }
        setLoading(false);
  
  }
 



    return (

      <div className="over_all_container">
      {loading === true ? <Loader /> : null}

           <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
           <Helmet>
                <meta charSet="utf-8" />
                <title>Schema Detail</title>
            </Helmet>
    
      <div className="cnt_audit_outer_wrap">
      
  <div className="cnt_hdr_top_wrap">
    <div class="box_wrap schema_anaysis_outer_wrap">
      <div className="cnt_hdr_top_lft_wrap">
        <h2>Schema Validation Detailed Report For</h2>
        <p><b>{SchemaUrl}</b></p>
        <p>as of {jobDate}</p>
      </div>
      <div className="cnt_hdr_top_rgt_wrap">
      <img src={pdficon} alt="pdf icon" onClick={pdfdown}/>
        
      </div>
    </div>
  </div>
<div className="cnt_hdr_content">
  <div className="box_wrap">
      <div>
        <h1></h1>
        <p> </p>
      </div>
      <div className="cnt_hdr_content_box_outer">
        <div className="error_back_schema" style={{color:"#020201"}}> 
            <h4>Missing</h4>
            <p>{ErrorCount}</p>
        </div>
        <div className="warning_back_schema" style={{color:"#020201"}}>
          <h4>Warning</h4>
          <p>{WarningCount}</p>
        </div>
      </div>
    </div>
  </div>
  
<div className="cnt_hdr_content_table">
  <div className="box_wrap schemaOutputeso" id="schemaOutput">

  </div>
</div>
 


  
      

    </div>
    <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}