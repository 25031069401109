import { IoMdCloseCircle } from 'react-icons/io'
import Faq from 'react-faq-component';
import { width } from '@mui/system';
import React, { useState, useEffect ,useRef} from "react";


const Rightsidebar = (props) => {
  const [keywordtest,setKeywordtest]=useState([])
  useEffect(() => {
    setKeywordtest(props.initialDateval)
    console.log("props.initialDateval",props.initialDateval)

  }, []);
  const data = {
    title: "FAQ",
    rows: props.initialfaqDateval
  }
  const styles = {
    // bgColor: 'white',
    titleTextColor: "#103658",
    rowTitleColor: "#103658",
    // rowContentColor: 'grey',
    arrowColor: "red",
    width:"30px",
};
  return <aside className='sidebar' >
   <div >
   <Faq data={data}
      styles={styles}/>
     </div>
  
</aside>
}

export default Rightsidebar;